export interface KakaoLoginRedirectState {
  r?: string;
  ice?: boolean;
}

export enum KakaoLoginExceptionCode {
  /**
   * 요청 파라미터 오류
   */
  invalidRequest = 'invalid_request',
  /**
   * 유효하지 않은 앱
   */
  invalidClient = 'invalid_client',
  /**
   * 유효하지 않은 scope ID
   */
  invalidScope = 'invalid_scope',
  /**
   * 인증 수단이 유효하지 않아 인증할 수 없는 상태
   */
  invalidGrant = 'invalid_grant',
  /**
   * 설정이 올바르지 않음 (android key hash)
   */
  misconfigured = 'misconfigured',
  /**
   * 앱이 요청 권한이 없음
   */
  unauthorized = 'unauthorized',
  /**
   * 접근이 거부 됨 (동의 취소)
   */
  accessDenied = 'access_denied',
  /**
   * 서버 내부 에러
   */
  serverError = 'server_error',
  /**
   * 기타 에러
   */
  unknown = 'unknown',
}
