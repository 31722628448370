import { Injectable } from '@angular/core';
import { FirebaseAnalytics, FirebaseInitOptions } from '@capacitor-community/firebase-analytics';

@Injectable({
  providedIn: 'root',
})
export class FirebaseAnalyticsService {
  /**
   * Platform: Web
   * Configure and initialize the firebase app.
   * @param options - firebase web app configuration options
   * */
  initializeFirebase(options: FirebaseInitOptions): void {
    FirebaseAnalytics.initializeFirebase(options);
  }

  /**
   * Platform: Web/Android/iOS
   * Sets the user ID property.
   * @param userId - unique identifier of a user
   * @returns void
   * https://firebase.google.com/docs/analytics/userid
   */
  setUserId(userId: string | null): Promise<void> {
    // https://github.com/capacitor-community/firebase-analytics/issues/49
    return FirebaseAnalytics.setUserId({ userId: userId! });
  }

  /**
   * Platform: Web/Android/iOS
   * Sets a user property to a given value.
   * @param name - property name to set
   * @param value - property value to set
   * @returns void
   * https://firebase.google.com/docs/analytics/user-properties
   */
  setUserProperty(name: string, value: string): Promise<void> {
    return FirebaseAnalytics.setUserProperty({ name, value });
  }

  /**
   * Platform: Android/iOS
   * Retrieves the app instance id from the service.
   * @returns instanceId - individual instance id value
   * https://firebase.google.com/docs/analytics/user-properties
   */
  async getAppInstanceId(): Promise<string | null> {
    return (await FirebaseAnalytics.getAppInstanceId()).instanceId;
  }

  /**
   * Platform: Android/iOS
   * Sets the current screen name, which specifies the current visual context in your app.
   * @param screenName - name of the current screen to track
   * @param nameOverride - name of the screen class to override
   * @returns instanceId - individual instance id value
   * https://firebase.google.com/docs/analytics/screenviews
   */
  setScreenName(screenName: string, nameOverride?: string): Promise<void> {
    return FirebaseAnalytics.setScreenName({ screenName, nameOverride });
  }

  /**
   * Platform: Web/Android/iOS
   * Clears all analytics data for this app from the device and resets the app instance id.
   * @param none
   * @returns void
   */
  reset(): Promise<void> {
    return FirebaseAnalytics.reset();
  }

  /**
   * Platform: Web/Android/iOS
   * Logs an app event.
   * @param name - name of the event to log
   * @param params - key/value pairs of properties (25 maximum per event)
   * @returns void
   */
  logEvent(name: string, params: object): Promise<void> {
    return FirebaseAnalytics.logEvent({ name, params });
  }

  /**
   * Platform: Web/Android/iOS
   * Sets whether analytics collection is enabled for this app on this device.
   * @param enabled - boolean true/false
   * @returns void
   */
  setCollectionEnabled(enabled: boolean): Promise<void> {
    return FirebaseAnalytics.setCollectionEnabled({ enabled });
  }

  /**
   * Platform: Web/Android/iOS
   * Sets the duration of inactivity that terminates the current session.
   * @param duration - duration in seconds (default - 18000)
   * @returns void
   */
  setSessionTimeoutDuration(duration: number): Promise<void> {
    return FirebaseAnalytics.setSessionTimeoutDuration({ duration });
  }

  /**
   * Platform: Web/Android/iOS
   * @deprecated Deprecated - use setCollectionEnabled() instead
   * Enable analytics collection for this app on this device.
   * @returns void
   */
  enable(): Promise<void> {
    return FirebaseAnalytics.enable();
  }

  /**
   * Platform: Web/Android/iOS
   * @deprecated Deprecated - use setCollectionEnabled() instead
   * Disable analytics collection for this app on this device.
   * @returns void
   */
  disable(): Promise<void> {
    return FirebaseAnalytics.disable();
  }
}
