import { APP_INITIALIZER, NgModule } from '@angular/core';
import { SharedModule } from '../modules/shared.module';
import { AppInitializerService } from './app-initializer/app-initializer.service';

export function init(appInitializerService: AppInitializerService) {
  return () => appInitializerService.init();
}

@NgModule({
  imports: [SharedModule],
  providers: [{ provide: APP_INITIALIZER, useFactory: init, deps: [AppInitializerService], multi: true }],
})
export class ServicesModule {}
