import { Subscription } from 'rxjs';

export const unsubscribeAll = (subscriptions: Array<Subscription | null | undefined>): void => {
  if (subscriptions == null) {
    return;
  }

  for (const sub of subscriptions) {
    if (sub != null && !sub.closed) {
      sub.unsubscribe();
    }
  }
};
