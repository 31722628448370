import { NgZone } from '@angular/core';
import { Observable, OperatorFunction } from 'rxjs';

// https://stackoverflow.com/a/57452361

export function runInZone<T>(ngZone: NgZone): OperatorFunction<T, T> {
  return (source) =>
    new Observable<T>((subscriber) =>
      source.subscribe({
        next(value: T) {
          ngZone.run(() => subscriber.next(value));
        },
        error(error: any) {
          ngZone.run(() => subscriber.error(error));
        },
        complete() {
          ngZone.run(() => subscriber.complete());
        },
      })
    );
}
