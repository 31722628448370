import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable, Subject, catchError, concatMap, map, race, skip, switchMap, take, tap, throwError } from 'rxjs';
import { PostUserSettingsParams } from '../../../types/api/user';
import { UserSettings } from '../../../types/common';
import { completeAll } from '../../../utils/complete-all';
import { ApiService } from '../api/api.service';
import { BaseService } from '../base.service';

@Injectable({ providedIn: 'root' })
export class UserSettingsService extends BaseService implements OnDestroy {
  readonly settings$: Observable<UserSettings | null>;

  private readonly settingsSubject = new BehaviorSubject<UserSettings | null>(null);
  private readonly refreshSubject = new Subject<void>();
  private readonly refreshErrorSubject = new Subject<any>();

  constructor(private apiService: ApiService) {
    super();

    this.settings$ = this.settingsSubject.asObservable();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    completeAll([this.settingsSubject, this.refreshSubject, this.refreshErrorSubject]);
  }

  /** 2번 이상 호출하지 않도록 주의 */
  async init(settings: UserSettings | null): Promise<void> {
    this.settingsSubject.next(settings);

    this.subscription = this.refreshSubject
      .pipe(
        switchMap(() =>
          this.apiService.getUserSettings({}).pipe(
            map((res) => res.result.settings),
            catchError((err) => {
              this.refreshErrorSubject.next(err);
              return EMPTY;
            })
          )
        )
      )
      .subscribe((settings) => {
        this.settingsSubject.next(settings);
      });
  }

  refresh(): Observable<void> {
    return new Observable<void>((subscriber) => {
      const sub = race(
        this.settings$.pipe(
          skip(1),
          take(1),
          map(() => undefined)
        ),
        this.refreshErrorSubject.pipe(
          take(1),
          concatMap((err) => throwError(() => err))
        )
      ).subscribe(subscriber);

      this.refreshSubject.next();

      return sub;
    });
  }

  update(params: PostUserSettingsParams): Observable<UserSettings> {
    return this.apiService.postUserSettings(params).pipe(
      map((res) => res.result.settings),
      tap((settings) => this.settingsSubject.next(settings))
    );
  }
}
