import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, catchError, concatMap, distinctUntilChanged, map, merge, mergeMap, switchMap, take } from 'rxjs';
import { AlertService } from '../../../../alert';
import { AuthService } from '../auth/auth.service';
import { BaseService } from '../base.service';
import { PrivacyScreenService } from '../privacy-screen/privacy-screen.service';
import { UserService } from '../user/user.service';

/** 스크린샷 및 화면 녹화 관련 알림 표시 및 서버 기록 담당 */
@Injectable({
  providedIn: 'root',
})
export class ScreenshotService extends BaseService {
  constructor(
    private router: Router,
    private translateService: TranslateService,
    private navCtrl: NavController,
    private alertService: AlertService,
    private authService: AuthService,
    private privacyScreenService: PrivacyScreenService,
    private userService: UserService
  ) {
    super();
  }

  /** 2번 이상 호출하지 않도록 주의 */
  init(): void {
    if (Capacitor.isNativePlatform()) {
      // 셀럽 사용자, 지원 사용자, 테스터 사용자만 스크린샷 허용
      // 비로그인 상태 또는 일반 사용자는 스크린샷 비허용
      this.subscription = this.userService.me$
        .pipe(
          map((userMe) => userMe == null || (!userMe.isCeleb && !userMe.isSupport && !userMe.isTester)),
          distinctUntilChanged(),
          concatMap((notLoggedInOrCommonUser) =>
            notLoggedInOrCommonUser ? this.privacyScreenService.enable().catch(() => {}) : this.privacyScreenService.disable().catch(() => {})
          )
        )
        .subscribe();

      // 스크린샷 비허용 상태에서 스크린샷 또는 화면 녹화 감지 시 알림 표시
      // 로그인 상태에서 알림 닫으면 로그아웃 및 시작 페이지로 이동
      this.subscription = this.privacyScreenService.enabled$
        .pipe(
          distinctUntilChanged(),
          switchMap((enabled) =>
            enabled ? merge(this.privacyScreenService.screenshotTaken$, this.privacyScreenService.screenRecordingStarted$) : EMPTY
          )
        )
        .subscribe(() => {
          this.alertService
            .alert(this.translateService.instant('SCREENSHOT.NOT_ALLOWED'), this.translateService.instant('SCREENSHOT.NOT_ALLOWED_DETAIL'))
            .then(() => {
              this.subscription = this.userService.me$.pipe(take(1)).subscribe((userMe) => {
                if (userMe != null) {
                  this.authService.signOut(true).subscribe(() => {
                    this.navCtrl.navigateBack(['/start']);
                  });
                }
              });
            });
        });

      // 로그인 상태에서 스크린샷 또는 화면 녹화 감지 시 서버에 기록
      this.subscription = this.userService.me$
        .pipe(
          map((userMe) => userMe?.id),
          distinctUntilChanged(),
          switchMap((userMeId) =>
            userMeId != null
              ? merge(
                  this.privacyScreenService.screenshotTaken$.pipe(map(() => 'screenshotTaken' as const)),
                  this.privacyScreenService.screenRecordingStarted$.pipe(map(() => 'screenRecordingStarted' as const))
                )
              : EMPTY
          ),
          mergeMap((evName) =>
            this.userService.screenshot({ path: this.router.url, isRecording: evName === 'screenRecordingStarted' ? true : undefined }).pipe(
              catchError((err) => {
                console.error(err);
                return EMPTY;
              })
            )
          )
        )
        .subscribe();
    }
  }
}
