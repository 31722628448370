import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { App, URLOpenListener, URLOpenListenerEvent } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { BehaviorSubject, EMPTY, Observable, distinctUntilChanged, shareReplay, switchMap } from 'rxjs';
import { completeAll } from '../../../utils/complete-all';
import { BaseService } from '../base.service';

@Injectable({
  providedIn: 'root',
})
export class DeepLinkService extends BaseService implements OnDestroy {
  readonly enabled$: Observable<boolean>;

  private readonly enabledSubject = new BehaviorSubject<boolean>(false);

  constructor(ngZone: NgZone, router: Router) {
    super();

    const appUrlOpen$ = Capacitor.isNativePlatform()
      ? new Observable<URLOpenListenerEvent>((subscriber) => {
          const listener: URLOpenListener = (ev) => {
            subscriber.next(ev);
          };

          const handle = App.addListener('appUrlOpen', listener);

          return () => {
            handle.remove();
            subscriber.complete();
          };
        })
      : EMPTY;

    const enabled$ = this.enabledSubject.pipe(distinctUntilChanged(), shareReplay(1));
    this.subscription = enabled$.subscribe();

    this.enabled$ = enabled$;

    this.subscription = enabled$.pipe(switchMap(() => appUrlOpen$)).subscribe((ev) => {
      ngZone.run(() => {
        try {
          const url = new URL(ev.url);
          router.navigateByUrl(url.pathname.replace(/^\/\/app/, '') + url.search, {
            state: { fromDeepLink: true },
          });
        } catch (err) {
          console.error(err);
        }
      });
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    completeAll([this.enabledSubject]);
  }

  enable(): void {
    this.enabledSubject.next(true);
  }

  disable(): void {
    this.enabledSubject.next(false);
  }
}
