import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { BehaviorSubject, combineLatest, defer, Observable } from 'rxjs';
import { concatMap, filter, map, shareReplay, take } from 'rxjs/operators';
import { Iamport as IamportCapacitor } from '../../../plugins/iamport';
import { CertificationParams, CertificationResponse, Iamport, RequestPayParams, RequestPayResponse } from '../../../types/iamport';
import { isNotNullish } from '../../../utils';
import { ScriptService } from '../script/script.service';

@Injectable({
  providedIn: 'root',
})
export class IamportService {
  private readonly capacitorAvailable = Capacitor.isNativePlatform();

  private readonly IMP$: Observable<Readonly<Iamport>>;

  private readonly userCodeSubject = new BehaviorSubject<string | null>(null);

  constructor(scriptService: ScriptService) {
    this.IMP$ = combineLatest([scriptService.iamport$, this.userCodeSubject.pipe(filter(isNotNullish))]).pipe(
      take(1),
      map(([IMP, userCode]) => {
        IMP.init(userCode);
        return IMP;
      }),
      shareReplay(1)
    );

    if (this.capacitorAvailable) {
      this.userCodeSubject.pipe(filter(isNotNullish)).subscribe((userCode) => {
        IamportCapacitor.init({ user_code: userCode });
      });
    }
  }

  init(userCode: string): void {
    this.userCodeSubject.next(userCode);
  }

  requestPay(params: RequestPayParams): Observable<RequestPayResponse> {
    if (this.capacitorAvailable) {
      return defer(() => IamportCapacitor.requestPay(params));
    }

    return this.IMP$.pipe(
      take(1),
      concatMap((IMP) => {
        return new Observable<RequestPayResponse>((subscriber) => {
          IMP.request_pay(params, (res) => {
            subscriber.next(res);
            subscriber.complete();
          });
        });
      })
    );
  }

  /** 본인인증 */
  certification(params: CertificationParams): Observable<CertificationResponse> {
    if (this.capacitorAvailable) {
      return defer(() => IamportCapacitor.certification(params));
    }

    return this.IMP$.pipe(
      take(1),
      concatMap((IMP) => {
        console.log('IMP', IMP);
        return new Observable<CertificationResponse>((subscriber) => {
          IMP.certification(params, (res) => {
            subscriber.next(res);
            subscriber.complete();
          });
        });
      })
    );
  }
}
