import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AlertButton, AlertComponentOptions, AlertInput } from './alert.type';

type AlertComponentValues = Array<any> & Record<string, any>;

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertComponent implements OnInit, AlertComponentOptions {
  /** ngOnInit 전에는 `undefined` */
  modal!: HTMLIonModalElement;

  header?: string;
  message?: string;
  inputs?: Array<AlertInput>;
  buttons?: Array<AlertButton>;

  values?: AlertComponentValues;

  ngOnInit(): void {
    if (this.inputs != null) {
      this.values = this.inputs.reduce((acc, cur, idx) => {
        acc[cur.name || idx] = cur.value;
        return acc;
      }, [] as AlertComponentValues);
    }
  }

  onChangeInput(input: AlertInput, index: number, ev: Event): void {
    const elem = ev.target as HTMLInputElement;
    if (elem != null && this.values != null) {
      this.values[index] = elem.value;
      if (input.name) {
        this.values[input.name] = elem.value;
      }
    }
  }

  onClickButton(button: AlertButton): void {
    this.modal.dismiss(this.values != null ? { values: this.values } : undefined, button.role);
  }
}
