<div class="modal-wrapper">
  <div class="modal">
    <div class="modal-title">
      <div class="alert-title">{{ header }}</div>
    </div>
    <div class="modal-content">
      <div class="alert-message">{{ message }}</div>
      <div *ngIf="inputs?.length" class="alert-inputs">
        <ng-container *ngFor="let input of inputs; let i = index">
          <div *ngIf="input.label" class="alert-input-label">
            {{ input.label }}
          </div>
          <ion-input
            [type]="input.type || 'text'"
            [name]="(input.name || i) + ''"
            [placeholder]="input.placeholder || ''"
            [value]="input.value"
            [disabled]="!!input.disabled"
            [min]="input.min"
            [max]="input.max"
            (input)="onChangeInput(input, i, $event)"
            (change)="onChangeInput(input, i, $event)"
          ></ion-input>
        </ng-container>
      </div>
    </div>
    <div *ngIf="buttons?.length" class="modal-footer">
      <div class="alert-buttons">
        <button
          *ngFor="let button of buttons"
          class="ion-activatable alert-button"
          [class.alert-button-clear]="button?.fill === 'clear'"
          [class.alert-button-outline]="button?.fill === 'outline'"
          [class.alert-button-solid]="button?.fill === 'solid'"
          [attr.data-ch-color]="button.color"
          (click)="onClickButton(button)"
        >
          {{ button.text }}
          <ion-ripple-effect></ion-ripple-effect>
        </button>
      </div>
    </div>
  </div>
</div>
