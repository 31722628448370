import { BehaviorSubject, merge, Observable } from 'rxjs';
import { filter, share, skip, takeUntil, tap } from 'rxjs/operators';
import { io, Socket } from 'socket.io-client';
import { environment } from 'src/environments/environment';
import { DateWrapped } from '../../../types/date-wrapper';
import { ClientToServerEvents, ServerToClientEvents } from '../../../types/socket-event';
import { Authenticated } from '../../../types/socket/authenticate';
import { unwrapDate } from '../../../utils/date-wrapper';

const SOCKET_ORIGIN = environment.socketOrigin;
const SOCKET_PATH = environment.socketPath;

// TODO: 타입 정리
type DateUnwrapped<T> = T extends DateWrapped<infer U> ? U : T;
type UnwrapParameters<T extends [...any[]]> = T extends [unknown]
  ? DateUnwrapped<T[0]>
  : { [I in keyof T]: DateUnwrapped<T[I]> } & {
      length: T['length'];
    };

// TODO: 연결 관리 개선 필요
export class SocketStream {
  get socket(): Socket<ServerToClientEvents, ClientToServerEvents> {
    return this.innerSocket;
  }

  get connected(): boolean {
    return this.connectionSubject.value;
  }

  get authenticated(): boolean {
    return this.authenticatedSubject.value;
  }

  private innerSocket: Socket;
  private accessToken: string | null = null;
  private subscriberCount = 0;
  private connectListener: () => void;
  private disconnectListener: () => void;
  private authenticatedListener: (result: Authenticated) => void;
  private connectionSubject = new BehaviorSubject<boolean>(false);
  private authenticatedSubject = new BehaviorSubject<boolean>(false);

  constructor(public readonly namespace: string) {
    const socket = io(`${SOCKET_ORIGIN}/${namespace}`, {
      path: SOCKET_PATH,
      autoConnect: false,
      transports: ['websocket'],
    });
    this.innerSocket = socket;
    this.connectListener = () => {
      this.connectionSubject.next(true);
      if (this.accessToken != null) {
        this.socket.emit('authenticate', this.accessToken);
      }
    };
    this.disconnectListener = () => {
      this.connectionSubject.next(false);
      this.authenticatedSubject.next(false);
    };
    this.authenticatedListener = (result) => {
      this.authenticatedSubject.next(result.authenticated);
    };
    this.socket.on('connect', this.connectListener);
    this.socket.on('disconnect', this.disconnectListener);
    this.socket.on('authenticated', this.authenticatedListener);
  }

  destroy(): void {
    this.socket.off('connect', this.connectListener);
    this.socket.off('disconnect', this.disconnectListener);
    this.socket.off('authenticated', this.authenticatedListener);
    this.disconnect();
    this.disconnectListener();
    this.connectionSubject.complete();
    this.authenticatedSubject.complete();
  }

  connect(): void {
    this.socket.connect();
  }

  disconnect(): void {
    this.socket.disconnect();
  }

  setAccessToken(accessToken: string | null): void {
    this.accessToken = accessToken;
    if (this.socket.connected) {
      this.socket.emit('authenticate', accessToken);
    }
  }

  fromEvent<Ev extends keyof ServerToClientEvents>(
    eventName: Ev,
    takeUntilDisconnection?: boolean
  ): Observable<UnwrapParameters<Parameters<ServerToClientEvents[Ev]>>> {
    return new Observable<UnwrapParameters<Parameters<ServerToClientEvents[Ev]>>>((subscriber) => {
      this.subscriberCount += 1;
      const handler = (...args: any[]) => subscriber.next(unwrapDate(args.length > 1 ? args : args[0]));
      this.socket.on(eventName, handler as any);
      if (this.subscriberCount === 1) {
        this.connect();
      }

      return () => {
        this.subscriberCount -= 1;
        this.socket.off(eventName, handler as any);
        if (this.subscriberCount === 0) {
          this.disconnect();
        }
      };
    }).pipe(
      takeUntilDisconnection
        ? takeUntil(
            this.connectionSubject.pipe(
              skip(1),
              filter((v) => v === false)
            )
          )
        : tap(),
      share()
    );
  }

  emit<Ev extends keyof ClientToServerEvents>(eventName: Ev, ...args: Parameters<ClientToServerEvents[Ev]>): void {
    this.socket.emit(eventName, ...args);
  }

  emitWaitForAuth<Ev extends keyof ClientToServerEvents>(eventName: Ev, ...args: Parameters<ClientToServerEvents[Ev]>): void {
    this.authenticatedSubject
      .pipe(
        filter((authenticated) => authenticated),
        takeUntil(
          merge(
            this.connectionSubject.pipe(
              skip(1),
              filter((v) => v === false)
            ),
            this.authenticatedSubject.pipe(
              skip(1),
              filter((v) => v === false)
            )
          )
        )
      )
      .subscribe(() => {
        this.socket.emit(eventName, ...args);
      });
  }
}
