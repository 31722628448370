import { Injectable } from '@angular/core';
import { COUNTRY_LIST } from './country-list';
import { CountryInfo } from './country.type';

@Injectable({
  providedIn: 'root',
})
export class CountryService {
  /**
   * IP 기반 국가 코드 (ISO 3166-1 alpha-2)
   *
   * 클라이언트 실행 시 서버에서 가져옴
   */
  get ipCountry(): string | undefined {
    return this._ipCountry;
  }

  private _ipCountry?: string;

  init(ipCountry?: string): void {
    this._ipCountry = ipCountry;
  }

  getAllCountries(): Array<CountryInfo> {
    return COUNTRY_LIST;
  }

  getCountry(code: string): CountryInfo | null {
    const lowerCode = code.toLowerCase();
    return COUNTRY_LIST.find((info) => info.code.toLowerCase() === lowerCode) ?? null;
  }

  getIpCountry(): CountryInfo | null {
    return this._ipCountry != null ? this.getCountry(this._ipCountry) : null;
  }

  /** 파일이 없는 경우에도 url을 반환하므로 주의 */
  getCountryFlagUrl(countryOrCode: string | CountryInfo): string {
    return `/assets/flags/32x32/${(typeof countryOrCode === 'string' ? countryOrCode : countryOrCode.code).toLowerCase()}.png`;
  }
}
