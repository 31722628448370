import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, map, merge } from 'rxjs';
import { PermissionDMEnter, PermissionDMSend } from '../../../types/common';
import { completeAll } from '../../../utils/complete-all';
import { BaseService } from '../base.service';
import { SocketService } from '../socket/socket.service';

interface InitData {
  permissionDMEnters: Array<PermissionDMEnter> | null;
  permissionDMSends: Array<PermissionDMSend> | null;
}

@Injectable({
  providedIn: 'root',
})
export class PermissionService extends BaseService implements OnDestroy {
  readonly dmEnter$: Observable<Array<PermissionDMEnter>>;
  readonly dmSend$: Observable<Array<PermissionDMSend>>;

  private readonly dmEnterSubject = new BehaviorSubject<Array<PermissionDMEnter>>([]);
  private readonly dmSendSubject = new BehaviorSubject<Array<PermissionDMSend>>([]);

  constructor(private socketService: SocketService) {
    super();

    this.dmEnter$ = this.dmEnterSubject.asObservable();
    this.dmSend$ = this.dmSendSubject.asObservable();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    completeAll([this.dmEnterSubject, this.dmSendSubject]);
  }

  /** 2번 이상 호출하지 않도록 주의 */
  async init(initData: InitData | null): Promise<void> {
    this.dmEnterSubject.next(initData?.permissionDMEnters ?? []);
    this.dmSendSubject.next(initData?.permissionDMSends ?? []);

    this.subscription = merge(
      this.socketService.fromEvent('permissionDMEnterAdd').pipe(map((ev) => ({ evName: 'permissionDMEnterAdd', ev }))),
      this.socketService.fromEvent('permissionDMEnterRemove').pipe(map((ev) => ({ evName: 'permissionDMEnterRemove', ev })))
    ).subscribe(({ evName, ev }) => {
      const newPermissionDMEnterList = this.dmEnterSubject.value.filter((permissionDMEnter) => permissionDMEnter.id !== ev.permissionDMEnter.id);
      if (evName === 'permissionDMEnterAdd') {
        newPermissionDMEnterList.push(ev.permissionDMEnter);
      }
      this.dmEnterSubject.next(newPermissionDMEnterList);
    });

    this.subscription = merge(
      this.socketService.fromEvent('permissionDMSendAdd').pipe(map((ev) => ({ evName: 'permissionDMSendAdd', ev }))),
      this.socketService.fromEvent('permissionDMSendRemove').pipe(map((ev) => ({ evName: 'permissionDMSendRemove', ev })))
    ).subscribe(({ evName, ev }) => {
      const newPermissionDMSendList = this.dmSendSubject.value.filter((permissionDMSend) => permissionDMSend.id !== ev.permissionDMSend.id);
      if (evName === 'permissionDMSendAdd') {
        newPermissionDMSendList.push(ev.permissionDMSend);
      }
      this.dmSendSubject.next(newPermissionDMSendList);
    });
  }
}
