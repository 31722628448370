import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '../../../alert';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  private _alertService?: AlertService;
  private _translateService?: TranslateService;

  // 순환 의존성 방지를 위해 Injector 사용
  constructor(private injector: Injector) {}

  private get alertService(): AlertService {
    if (this._alertService == null) {
      this._alertService = this.injector.get(AlertService);
    }
    return this._alertService;
  }

  private get translateService(): TranslateService {
    if (this._translateService == null) {
      this._translateService = this.injector.get(TranslateService);
    }
    return this._translateService;
  }

  handleError(error: any): void {
    let errorMessage = this.translateService.instant('ERROR.UNKNOWN_ERROR');

    if (error instanceof HttpErrorResponse) {
      // 서버 오류
      if (!navigator.onLine) {
        errorMessage = this.translateService.instant('ERROR.CHECK_NETWORK');
      } else {
        errorMessage = error.message;
      }
    } else if (error instanceof Error) {
      // 클라이언트 오류
      errorMessage = error.message || error + '';
    }

    console.error(error);
    this.alertService.alert(this.translateService.instant('COMMON.ERROR'), errorMessage);
  }
}
