import { Injectable, Injector } from '@angular/core';
import { AppInjector } from '../app-injector';

@Injectable({
  providedIn: 'root',
})
export class AppInitializerService {
  constructor(private injector: Injector) {}

  async init(): Promise<void> {
    AppInjector.setInjector(this.injector);
  }
}
