export function tryParseInt(value: unknown): number | null {
  if (value == null || Number.isNaN(value)) {
    return null;
  } else if (typeof value === 'number') {
    return value;
  }

  try {
    const parsed = parseInt(value + '', 10);
    return Number.isNaN(parsed) ? null : parsed;
  } catch (err) {
    return null;
  }
}
