import { registerPlugin } from '@capacitor/core';

import type { KakaoLoginPlugin } from './definitions';
import { KakaoLoginWeb } from './web';

const KakaoLogin = registerPlugin<KakaoLoginPlugin>('KakaoLogin', {
  web: () => new KakaoLoginWeb(),
});

export * from './definitions';
export { KakaoLogin };
