import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, catchError, concatMap, firstValueFrom, map, of, skip, switchMap, take } from 'rxjs';
import { completeAll } from '../../../utils/complete-all';
import { ApiService } from '../api/api.service';
import { BaseService } from '../base.service';
import { PushNotificationService } from '../push-notification/push-notification.service';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root',
})
export class UserPushNotificationService extends BaseService implements OnDestroy {
  readonly token$: Observable<string | null>;

  private readonly tokenSubject = new BehaviorSubject<string | null>(null);

  constructor(private apiService: ApiService, private pushNotificationService: PushNotificationService, private userService: UserService) {
    super();

    this.token$ = this.tokenSubject.asObservable();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    completeAll([this.tokenSubject]);
  }

  /** 2번 이상 호출하지 않도록 주의 */
  async init(): Promise<void> {
    this.tokenSubject.next(
      await firstValueFrom(
        this.userService.me$.pipe(
          take(1),
          switchMap((userMe) =>
            userMe != null ? this.pushNotificationService.requestToken().pipe(concatMap((token) => this.setPushToken(token))) : of(null)
          )
        )
      )
    );

    this.subscription = this.userService.me$
      .pipe(
        skip(1),
        switchMap((userMe) =>
          userMe != null ? this.pushNotificationService.requestToken().pipe(concatMap((token) => this.setPushToken(token))) : of(null)
        )
      )
      .subscribe((token) => {
        this.tokenSubject.next(token);
      });
  }

  setPushToken(pushToken: string | null): Observable<string | null> {
    return this.apiService.postUserPushToken({ pushToken }).pipe(
      map(() => pushToken),
      catchError((err) => {
        console.error(err);
        return of(null);
      })
    );
  }
}
