import { Location } from '@angular/common';
import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { IonRouterOutlet, NavController, Platform } from '@ionic/angular';
import { Observable, Subject } from 'rxjs';
import { concatMap, filter, map, take, withLatestFrom } from 'rxjs/operators';
import { completeAll } from '../../../utils/complete-all';
import { BaseService } from '../base.service';
import { OnHardwareBack } from './back-button.type';

const ROOT_URLS: Array<string | RegExp> = ['/', '/start', /^\/tabs/];

function isOnHardwareBack(cmp: any): cmp is OnHardwareBack {
  return typeof cmp?.appOnHardwareBack === 'function';
}

@Injectable({
  providedIn: 'root',
})
export class BackButtonService extends BaseService implements OnDestroy {
  private readonly initValuesSubject = new Subject<{
    ionRouterOutlet: IonRouterOutlet;
  }>();

  constructor(router: Router, location: Location, private platform: Platform, navCtrl: NavController) {
    super();

    const initValues$ = this.initValuesSubject.asObservable();

    const cmpRef$ = initValues$.pipe(
      take(1),
      concatMap((values) =>
        router.events.pipe(
          filter((ev): ev is NavigationEnd => ev instanceof NavigationEnd),
          map(() => values.ionRouterOutlet.activatedView?.ref)
        )
      )
    );

    if (Capacitor.getPlatform() === 'android') {
      this.subscription = initValues$
        .pipe(
          take(1),
          concatMap((values) => this.fromBackButton(0).pipe(map(() => values))),
          withLatestFrom(cmpRef$)
        )
        .subscribe(([values, cmpRef]) => {
          const { ionRouterOutlet } = values;

          const componentInstance = cmpRef?.instance;
          if (isOnHardwareBack(componentInstance) && componentInstance.appOnHardwareBack()) {
            return;
          }

          const ionBackButton: HTMLIonBackButtonElement | null = cmpRef?.location.nativeElement?.querySelector('ion-back-button');
          if (ionBackButton != null) {
            ionBackButton.click();
            return;
          }

          const routerUrl = router.url.match(/^(\/[^?]+)/)?.[1] ?? '/';
          if (ROOT_URLS.some((url) => (typeof url === 'string' ? url === routerUrl : url.test(routerUrl)))) {
            App.exitApp().catch((err) => {
              console.error(err);
            });
            return;
          }

          if (ionRouterOutlet.canGoBack()) {
            location.back();
            return;
          }

          navCtrl.navigateBack('/', { replaceUrl: true });
        });
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    completeAll([this.initValuesSubject]);
  }

  init(ionRouterOutlet: IonRouterOutlet): void {
    this.initValuesSubject.next({ ionRouterOutlet });
  }

  fromBackButton(priority: number): Observable<void> {
    return new Observable<void>((subscriber) =>
      this.platform.backButton.subscribeWithPriority(priority, () => {
        subscriber.next();
      })
    );
  }
}
