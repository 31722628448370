import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { authGuard } from './common/guards/auth/auth.guard';
import { bootGuard } from './common/guards/boot/boot.guard';
import { nonAuthGuard } from './common/guards/non-auth/non-auth.guard';
import { pointBuyGuard } from './common/guards/point-buy/point-buy.guard';

const routes: Routes = [
  {
    path: 'boot',
    loadChildren: () => import('./pages/boot').then((m) => m.BootPageModule),
  },
  {
    path: '',
    canActivate: [bootGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'start',
      },
      {
        path: 'sign-in',
        loadChildren: () => import('./pages/sign-in').then((m) => m.SignInPageModule),
      },
      {
        path: 'sign-up',
        loadChildren: () => import('./pages/sign-up').then((m) => m.SignUpPageModule),
      },
      {
        path: 'oauth/kakao',
        loadChildren: () => import('./pages/oauth-kakao').then((m) => m.OauthKakaoPageModule),
      },
      {
        path: 'verify-identity',
        loadChildren: () => import('./pages/verify-identity').then((m) => m.VerifyIdentityPageModule),
      },
      {
        path: 'start',
        canActivate: [nonAuthGuard],
        loadChildren: () => import('./pages/start').then((m) => m.StartPageModule),
      },
      {
        path: 'policy/terms',
        loadChildren: () => import('./pages/policy-terms').then((m) => m.PolicyTermsPageModule),
      },
      {
        path: 'policy/operation',
        loadChildren: () => import('./pages/policy-operation').then((m) => m.PolicyOperationPageModule),
      },
      {
        path: 'policy/privacy',
        loadChildren: () => import('./pages/policy-privacy').then((m) => m.PolicyPrivacyPageModule),
      },
      {
        path: 'customer-service',
        loadChildren: () => import('./pages/customer-service').then((m) => m.CustomerServicePageModule),
      },
      {
        path: 'version',
        loadChildren: () => import('./pages/version').then((m) => m.VersionPageModule),
      },
      {
        path: 'reset-password',
        loadChildren: () => import('./pages/reset-password').then((m) => m.ResetPasswordPageModule),
      },
      {
        path: 'point-buy',
        canActivate: [pointBuyGuard],
        loadChildren: () => import('./pages/point-buy').then((m) => m.PointBuyPageModule),
      },
      {
        path: 'language',
        canActivate: [pointBuyGuard],
        loadChildren: () => import('./pages/language').then((m) => m.LanguagePageModule),
      },
      {
        path: '',
        canActivate: [authGuard],
        children: [
          {
            path: '',
            loadChildren: () => import('./pages/tabs').then((m) => m.TabsPageModule),
          },
          {
            path: 'chat',
            loadChildren: () => import('./pages/chat').then((m) => m.ChatPageModule),
          },
          {
            path: 'settings',
            loadChildren: () => import('./pages/settings').then((m) => m.SettingsPageModule),
          },
          {
            path: 'change-password',
            loadChildren: () => import('./pages/change-password').then((m) => m.ChangePasswordPageModule),
          },
          {
            path: 'search-user',
            loadChildren: () => import('./pages/search-user').then((m) => m.SearchUserPageModule),
          },
          {
            path: 'set-profile',
            loadChildren: () => import('./pages/set-profile').then((m) => m.SetProfilePageModule),
          },
          {
            path: 'point-history',
            loadChildren: () => import('./pages/point-history').then((m) => m.PointHistoryPageModule),
          },
          {
            path: 'sign-up-done',
            loadChildren: () => import('./pages/sign-up-done').then((m) => m.SignUpDonePageModule),
          },
          {
            path: 'point-order',
            loadChildren: () => import('./pages/point-order-detail').then((m) => m.PointOrderDetailPageModule),
          },
          {
            path: 'blocking-user',
            loadChildren: () => import('./pages/blocking-user').then((m) => m.BlockingUserPageModule),
          },
          {
            path: 'muting-user',
            loadChildren: () => import('./pages/muting-user').then((m) => m.MutingUserPageModule),
          },
          {
            path: 'celeb-application',
            loadChildren: () => import('./pages/celeb-application').then((m) => m.CelebApplicationPageModule),
          },
          {
            path: 'bank-account',
            loadChildren: () => import('./pages/bank-account').then((m) => m.BankAccountPageModule),
          },
          {
            path: 'settlement',
            loadChildren: () => import('./pages/settlement').then((m) => m.SettlementPageModule),
          },
          {
            path: 'notice',
            loadChildren: () => import('./pages/notice').then((m) => m.NoticePageModule),
          },
          {
            path: 'celeb-point-history',
            loadChildren: () => import('./pages/celeb-point-history').then((m) => m.CelebPointHistoryPageModule),
          },
          {
            path: 'subscription',
            loadChildren: () => import('./pages/subscription').then((m) => m.SubscriptionPageModule),
          },
          {
            path: 'payment-method',
            loadChildren: () => import('./pages/payment-method').then((m) => m.PaymentMethodPageModule),
          },
          {
            path: 'subscription-settlement',
            loadChildren: () => import('./pages/subscription-settlement').then((m) => m.SubscriptionSettlementPageModule),
          },
          {
            path: 'subscribers',
            loadChildren: () => import('./pages/subscribers').then((m) => m.SubscribersPageModule),
          },
          {
            path: 'subscribing',
            loadChildren: () => import('./pages/subscribing').then((m) => m.SubscribingPageModule),
          },
          {
            path: 'premium-subscribers',
            loadChildren: () => import('./pages/premium-subscribers').then((m) => m.PremiumSubscribersPageModule),
          },
          {
            path: 'premium-subscribing',
            loadChildren: () => import('./pages/premium-subscribing').then((m) => m.PremiumSubscribingPageModule),
          },
          {
            path: 'celebs',
            loadChildren: () => import('./pages/celebs').then((m) => m.CelebsPageModule),
          },
          {
            path: 'cancel-subscription-premium',
            loadChildren: () => import('./pages/cancel-subscription-premium').then((m) => m.CancelSubscriptionPremiumPageModule),
          },
          {
            path: 'verify-email',
            loadChildren: () => import('./pages/verify-email').then((m) => m.VerifyEmailPageModule),
          },
          {
            path: 'verify-phone-number',
            loadChildren: () => import('./pages/verify-phone-number').then((m) => m.VerifyPhoneNumberPageModule),
          },
          {
            path: 'post-editor',
            loadChildren: () => import('./pages/post-editor').then((m) => m.PostEditorPageModule),
          },
        ],
      },
      {
        path: '',
        loadChildren: () => import('./pages/user').then((m) => m.UserPageModule),
      },
      {
        path: '**',
        loadChildren: () => import('./pages/not-found').then((m) => m.NotFoundPageModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
