import { Injectable, OnDestroy } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { BehaviorSubject, Observable, catchError, concatMap, firstValueFrom, map, of, throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { APIErrorCode } from '../../../constants/api-error-code';
import { PostAuthBootResult } from '../../../types/api/auth';
import { completeAll } from '../../../utils/complete-all';
import { isAPIErrorWithCode } from '../../../utils/is-api-error-with-code';
import { AccessTokenService } from '../access-token/access-token.service';
import { ApiService } from '../api/api.service';
import { AuthService } from '../auth/auth.service';
import { BackButtonService } from '../back-button/back-button.service';
import { BaseService } from '../base.service';
import { ChatService } from '../chat/chat.service';
import { CountryService } from '../country/country.service';
import { DeepLinkService } from '../deep-link/deep-link.service';
import { DeviceInfoService } from '../device-info/device-info.service';
import { FirebaseAnalyticsService } from '../firebase-analytics/firebase-analytics.service';
import { IamportService } from '../iamport/iamport.service';
import { InAppPurchaseService } from '../in-app-purchase/in-app-purchase.service';
import { KakaoLoginService } from '../kakao-login/kakao-login.service';
import { LanguageService } from '../language';
import { NoticeService } from '../notice/notice.service';
import { PermissionService } from '../permission/permission.service';
import { PushNotificationService } from '../push-notification/push-notification.service';
import { ScreenshotService } from '../screenshot/screenshot.service';
import { SubscriptionService } from '../subscription/subscription.service';
import { UserPushNotificationService } from '../user-push-notification/user-push-notification.service';
import { UserSettingsService } from '../user-settings/user-settings.service';
import { UserService } from '../user/user.service';
import { VersionService } from '../version/version.service';
import { BootOptions } from './boot.type';

type BootState = 'before' | 'working' | 'done' | 'error';

@Injectable({
  providedIn: 'root',
})
export class BootService extends BaseService implements OnDestroy {
  get bootState(): BootState {
    return this.bootStateSubject.value;
  }

  readonly bootState$: Observable<BootState>;
  readonly bootStateText$: Observable<string>;

  private readonly bootStateSubject = new BehaviorSubject<BootState>('before');
  private readonly bootStateTextSubject = new BehaviorSubject<string>('초기화 중...');
  private bootPromise?: Promise<void>;

  constructor(
    private platform: Platform,
    private storage: Storage,
    private accessTokenService: AccessTokenService,
    private apiService: ApiService,
    private authService: AuthService,
    private backButtonService: BackButtonService,
    private chatService: ChatService,
    private countryService: CountryService,
    private deepLinkService: DeepLinkService,
    private deviceInfoService: DeviceInfoService,
    private firebaseAnalyticsService: FirebaseAnalyticsService,
    private iamportService: IamportService,
    private inAppPurchaseService: InAppPurchaseService,
    private kakaoLoginService: KakaoLoginService,
    private languageService: LanguageService,
    private noticeService: NoticeService,
    private permissionService: PermissionService,
    private pushNotificationService: PushNotificationService,
    private screenshotService: ScreenshotService,
    private subscriptionService: SubscriptionService,
    private userService: UserService,
    private userPushNotificationService: UserPushNotificationService,
    private userSettingsService: UserSettingsService,
    private versionService: VersionService
  ) {
    super();

    this.bootState$ = this.bootStateSubject.asObservable();
    this.bootStateText$ = this.bootStateTextSubject.asObservable();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    completeAll([this.bootStateSubject, this.bootStateTextSubject]);
  }

  async boot(bootOptions: BootOptions): Promise<void> {
    if (!this.bootPromise) {
      this.bootPromise = this.innerBoot(bootOptions).then(
        () => {
          this.bootStateSubject.next('done');
        },
        (err) => {
          this.bootStateSubject.next('error');
          throw err;
        }
      );
    }

    await this.bootPromise;
  }

  private async innerBoot(bootOptions: BootOptions): Promise<void> {
    this.bootStateTextSubject.next('앱 초기화 중...');
    await this.platform.ready();
    await Promise.all([this.storage.create(), this.deviceInfoService.init()]);
    this.screenshotService.init();
    this.backButtonService.init(bootOptions.ionRouterOutlet);
    this.deepLinkService.enable();
    if (Capacitor.getPlatform() === 'web') {
      this.firebaseAnalyticsService.initializeFirebase(environment.firebaseConfig);
    }
    this.iamportService.init(environment.iamport.userCode);
    await this.inAppPurchaseService.init();
    this.kakaoLoginService.init(environment.kakaoAppKey);

    this.bootStateTextSubject.next('저장된 정보 불러오는 중...');
    await this.languageService.init();
    await this.accessTokenService.init();

    this.bootStateTextSubject.next('정보 불러오는 중...');
    const bootData = await firstValueFrom(
      this.apiService
        .postAuthBoot({
          deviceInfo: this.deviceInfoService.deviceInfo ?? undefined,
          responseData: ['version', 'user', 'settings', 'permissions', 'subscriptions', 'notices', 'country'],
        })
        .pipe(
          map((res) => res.result),
          catchError((err) => {
            if (isAPIErrorWithCode(err, APIErrorCode.UPDATE_REQUIRED)) {
              return of({
                minVersion: err.data?.minVersion ?? null,
                minVersionName: err.data?.minVersionName ?? null,
                latestVersion: err.data?.latestVersion ?? null,
                latestVersionName: err.data?.latestVersionName ?? null,
                user: null,
                settings: null,
                permissionDMEnters: [],
                permissionDMSends: [],
                subscriptions: [],
                subscriptionPremiums: [],
                notices: [],
              } as PostAuthBootResult);
            }
            // TODO: 처리 방식 변경
            if (err instanceof Error && (err.message === '세션이 만료되었습니다.' || err.message === '세션을 찾을 수 없습니다.')) {
              return this.authService.signOut(true).pipe(
                concatMap(() =>
                  this.apiService
                    .postAuthBoot({
                      deviceInfo: this.deviceInfoService.deviceInfo ?? undefined,
                      responseData: ['version', 'user', 'settings', 'permissions', 'subscriptions', 'notices', 'country'],
                    })
                    .pipe(map((res2) => res2.result))
                )
              );
            }
            return throwError(() => err);
          })
        )
    );
    await this.versionService.init({
      minVersion: bootData.minVersion,
      minVersionName: bootData.minVersionName,
      latestVersion: bootData.latestVersion,
      latestVersionName: bootData.latestVersionName,
    });
    this.countryService.init(bootData.country);

    if (this.versionService.version.updateRequired) {
      this.bootStateTextSubject.next('마무리 중...');
      await new Promise((resolve) => requestAnimationFrame(resolve));
      return;
    }

    this.bootStateTextSubject.next('사용자 불러오는 중...');
    this.authService.bootResult(bootData.user != null);
    await this.userService.init(bootData.user);

    this.bootStateTextSubject.next('사용자 설정 불러오는 중...');
    await this.userSettingsService.init(bootData.settings);

    this.bootStateTextSubject.next('푸시 알림 설정 중...');
    await this.pushNotificationService.init();
    await this.userPushNotificationService.init();

    this.bootStateTextSubject.next('권한 정보 불러오는 중...');
    await this.permissionService.init({
      permissionDMEnters: bootData.permissionDMEnters,
      permissionDMSends: bootData.permissionDMSends,
    });

    this.bootStateTextSubject.next('구독 정보 불러오는 중...');
    await this.subscriptionService.init({
      subscriptions: bootData.subscriptions,
      subscriptionPremiums: bootData.subscriptionPremiums,
    });

    this.bootStateTextSubject.next('채팅 불러오는 중...');
    await this.chatService.init();

    this.bootStateTextSubject.next('공지 불러오는 중...');
    await this.noticeService.init(bootData.notices);

    this.bootStateTextSubject.next('마무리 중...');
    await new Promise((resolve) => requestAnimationFrame(resolve));
  }
}
