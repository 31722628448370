import { LocationStrategy } from '@angular/common';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, firstValueFrom, from, Observable, of, throwError } from 'rxjs';
import { concatMap, delay, first, map, tap } from 'rxjs/operators';
import { OauthKakaoFailureMessage, OauthKakaoSuccessMessage } from '../../../types/oauth-kakao-message';
import { hasKeyExtend } from '../../../utils/has-key';
import { openWindow } from '../../../utils/open-window';
import { BaseService } from '../base.service';
import { ScriptService } from '../script/script.service';
import { KakaoLoginWebAuthorizePopupResult } from './kakao-login-web.type';

type KakaoType = Readonly<typeof window.Kakao>;

function isOauthKakaoSuccessMessage(value: unknown): value is OauthKakaoSuccessMessage {
  return (
    hasKeyExtend(value, 'type') &&
    value.type === 'oauth-kakao-success' &&
    hasKeyExtend(value, 'detail') &&
    hasKeyExtend(value.detail, 'code') &&
    typeof value.detail.code === 'string'
  );
}

function isOauthKakaoFailureMessage(value: unknown): value is OauthKakaoFailureMessage {
  return (
    hasKeyExtend(value, 'type') &&
    value.type === 'oauth-kakao-failure' &&
    hasKeyExtend(value, 'detail') &&
    hasKeyExtend(value.detail, 'error') &&
    typeof value.detail.error === 'string'
  );
}

// TODO: 테스트 필요
@Injectable({
  providedIn: 'root',
})
export class KakaoLoginWebService extends BaseService {
  private appKey?: string;
  private kakaoPromise?: Promise<KakaoType>;

  constructor(private locationStrategy: LocationStrategy, private translateService: TranslateService, private scriptService: ScriptService) {
    super();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  init(appKey: string): void {
    this.appKey = appKey;
  }

  getStatusInfo(): Observable<Kakao.Auth.AuthStatusObject> {
    return from(this.loadKakao()).pipe(
      concatMap((Kakao) => {
        return new Observable<Kakao.Auth.AuthStatusObject>((subscriber) => {
          Kakao.Auth.getStatusInfo((object) => {
            subscriber.next(object);
            subscriber.complete();
          });
        });
      })
    );
  }

  authorize(settings: Parameters<typeof Kakao.Auth.authorize>[0]): Observable<void> {
    return from(this.loadKakao()).pipe(
      tap((Kakao) => Kakao.Auth.authorize(settings)),
      delay(1000),
      tap((Kakao) => Kakao.Auth.authorize({ ...settings, throughTalk: false })),
      map(() => undefined)
    );
  }

  logout(): Observable<void> {
    return from(this.loadKakao()).pipe(
      concatMap((Kakao) => {
        return new Observable<void>((subscriber) => {
          if (!Kakao.Auth.getAccessToken()) {
            subscriber.next();
            subscriber.complete();
          } else {
            Kakao.Auth.logout(() => {
              subscriber.next();
              subscriber.complete();
            });
          }
        });
      })
    );
  }

  authorizePopup(): Observable<KakaoLoginWebAuthorizePopupResult> {
    const origin = window.location.origin;
    const baseHref = this.locationStrategy.getBaseHref();
    const redirectUri = `${origin}${baseHref.replace(/\/$/, '')}/oauth/kakao`;

    const width = 480;
    const height = 700;
    const sLeft = window.screenLeft ? window.screenLeft : window.screenX ? window.screenX : 0;
    const sTop = window.screenTop ? window.screenTop : window.screenY ? window.screenY : 0;
    const left = screen.width / 2 - width / 2 + sLeft;
    const top = screen.height / 2 - height / 2 + sTop;

    return openWindow(redirectUri, '_blank', `popup=1,width=${width},height=${height},left=${left},top=${top}`, 200, 1000).pipe(
      map((ev) => ev.data),
      concatMap((message) =>
        isOauthKakaoSuccessMessage(message)
          ? of({ code: message.detail.code, redirectUri })
          : isOauthKakaoFailureMessage(message)
          ? throwError(() => new Error(message.detail.error))
          : EMPTY
      ),
      first(undefined, { code: null, redirectUri })
    );
  }

  private async loadKakao(): Promise<KakaoType> {
    const { appKey } = this;

    if (appKey == null) {
      throw new Error(this.translateService.instant('ACCOUNT.ERR_KAKAO_SIGN_IN_NOT_AVAILABLE'));
    }

    if (this.kakaoPromise == null) {
      this.kakaoPromise = firstValueFrom(this.scriptService.kakao$).then(
        (Kakao) => {
          Kakao.init(appKey);
          return Kakao;
        },
        (err) => {
          this.kakaoPromise = undefined;
          throw err;
        }
      );
    }

    return this.kakaoPromise;
  }
}
