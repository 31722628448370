import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '../../../../alert';
import { hasKey } from '../../../utils/has-key';
import { BaseService } from '../base.service';
import { Language, LanguageCode } from './language.type';

/** 언어 설정 담당 */
@Injectable({
  providedIn: 'root',
})
export class LanguageService extends BaseService {
  static readonly languages: Record<LanguageCode, Language> = {
    ko: {
      code: 'ko',
      name: '한국어',
      enName: 'Korean',
    },
    en: {
      code: 'en',
      name: 'English',
      enName: 'English',
    },
  };

  get defaultLang(): LanguageCode | undefined {
    return this.translateService.defaultLang as LanguageCode | undefined;
  }

  get currentLang(): LanguageCode | undefined {
    return this.translateService.currentLang as LanguageCode | undefined;
  }

  get currentLangWithFallback(): LanguageCode {
    return this.currentLang || this.defaultLang || 'en';
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private storage: Storage,
    private translateService: TranslateService,
    private alertService: AlertService
  ) {
    super();
  }

  async init(): Promise<void> {
    // 저장된 언어 불러오기
    const savedLanguageCode = await this.storage.get('celebhere.language.ui');
    if (this.getLanguageAvailable(savedLanguageCode)) {
      this.use(savedLanguageCode, true);
    } else {
      // 저장된 언어가 없거나 사용 불가 시 다른 언어 자동 선택
      this.use(this.getAutomaticLang() ?? this.defaultLang ?? 'en');
    }

    // 알림 기본 확인 텍스트 설정
    this.subscription = this.translateService.get('COMMON.OK').subscribe((text) => {
      this.alertService.defaultOkText = text;
    });
    // 알림 기본 취소 텍스트 설정
    this.subscription = this.translateService.get('COMMON.CANCEL').subscribe((text) => {
      this.alertService.defaultCancelText = text;
    });
    // 오류 알림 헤더 설정
    this.subscription = this.translateService.get('COMMON.ALERT').subscribe((text) => {
      this.alertService.errorHeader = text;
    });

    // 언어 변경 시 HTML lang 속성에 반영
    this.document.documentElement.lang = this.currentLang ?? this.defaultLang ?? 'en';
    this.subscription = this.translateService.onLangChange.subscribe((ev) => {
      this.document.documentElement.lang = ev.lang;
    });
  }

  use(languageCode: LanguageCode, withoutSaving?: boolean): void {
    if (!hasKey(LanguageService.languages, languageCode)) {
      languageCode = this.defaultLang ?? 'en';
    }
    this.translateService.use(languageCode);
    if (!withoutSaving) {
      this.storage.set('celebhere.language.ui', languageCode);
    }
  }

  /** 지원하는 언어 여부 확인 */
  getLanguageAvailable(languageCode: string): languageCode is LanguageCode {
    return hasKey(LanguageService.languages, languageCode);
  }

  /**
   * 브라우저 언어 설정에 따라 지원하는 언어 코드 가져오기
   *
   * 일치하는 언어가 없는 경우 `null` 반환
   */
  getAutomaticLang(): LanguageCode | null {
    const browserCultureLang = this.translateService.getBrowserCultureLang();
    if (browserCultureLang != null && this.getLanguageAvailable(browserCultureLang)) {
      return browserCultureLang;
    }

    const browserLang = this.translateService.getBrowserLang();
    if (browserLang != null && this.getLanguageAvailable(browserLang)) {
      return browserLang;
    }

    return null;
  }
}
