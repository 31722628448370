import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FCM } from '@capacitor-community/fcm';
import { Capacitor, PermissionState } from '@capacitor/core';
import { ActionPerformed as PushNotificationActionPerformed, PushNotifications } from '@capacitor/push-notifications';
import { BehaviorSubject, Observable, Subject, combineLatest, firstValueFrom, from, of } from 'rxjs';
import { catchError, concatMap, exhaustMap, filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { isNotNullish } from '../../../utils';
import { completeAll } from '../../../utils/complete-all';
import { BaseService } from '../base.service';

// TODO: 다국어 지원
@Injectable({
  providedIn: 'root',
})
export class PushNotificationService extends BaseService implements OnDestroy {
  readonly permissionState$: Observable<PermissionState>;
  readonly token$: Observable<string | null>;

  private readonly permissionStateSubject = new BehaviorSubject<PermissionState>('prompt');
  private readonly tokenSubject = new BehaviorSubject<string | null>(null);
  private readonly requestTokenSubject = new Subject<void>();

  constructor(private ngZone: NgZone, private router: Router) {
    super();

    this.permissionState$ = this.permissionStateSubject.asObservable();
    this.token$ = this.tokenSubject.asObservable();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    completeAll([this.permissionStateSubject, this.tokenSubject, this.requestTokenSubject]);
  }

  /** 2번 이상 호출하지 않도록 주의 */
  async init(): Promise<void> {
    const initialPermissionState = await firstValueFrom(
      this.checkPermissions().pipe(
        catchError((err) => {
          console.error(err);
          const permissionState: PermissionState = 'denied';
          return of(permissionState);
        })
      )
    );

    const initialToken =
      initialPermissionState === 'granted'
        ? await firstValueFrom(
            this.register().pipe(
              catchError((err) => {
                console.error(err);
                return of(null);
              })
            )
          )
        : null;

    this.permissionStateSubject.next(initialPermissionState);
    this.tokenSubject.next(initialToken);

    this.subscription = this.requestTokenSubject
      .pipe(
        withLatestFrom(this.permissionState$),
        exhaustMap(([, permissionState]) =>
          permissionState !== 'granted'
            ? combineLatest([
                this.requestPermissions().pipe(
                  catchError((err) => {
                    console.error(err);
                    const permissionState: PermissionState = 'denied';
                    return of(permissionState);
                  }),
                  tap((permissionState) => {
                    this.permissionStateSubject.next(permissionState);
                  })
                ),
              ])
            : of(permissionState)
        ),
        concatMap((permissionState) => (permissionState === 'granted' ? this.register() : of(null)))
      )
      .subscribe((token) => {
        this.tokenSubject.next(token);
      });

    const pushNotificationActionPerformed$ = new Observable<PushNotificationActionPerformed>((subscriber) => {
      const handle = PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
        subscriber.next(notification);
      });

      return () => {
        handle.remove();
      };
    });

    this.subscription = this.permissionState$
      .pipe(
        filter((permissionState) => permissionState === 'granted'),
        switchMap(() => pushNotificationActionPerformed$)
      )
      .subscribe((ev) => {
        const link = typeof ev.notification.data.link === 'string' ? (ev.notification.data.link as string) : undefined;

        if (link) {
          const internalLinkMatch = link.match(/^https?:\/\/(?:app|app-dev)\.celebhere\.com(.+)$/);
          const internalLink = link.startsWith('/') ? link : internalLinkMatch != null ? internalLinkMatch[1] : undefined;

          if (internalLink) {
            this.ngZone.run(() => {
              try {
                this.router.navigateByUrl(internalLink);
              } catch (err) {
                console.error(err);
              }
            });
          } else {
            window.open(link, '_blank');
          }
        }
      });
  }

  requestToken(): Observable<string | null> {
    return new Observable<string | null>((subscriber) => {
      const sub = this.tokenSubject.pipe(take(1)).subscribe(subscriber);

      this.requestTokenSubject.next();

      return sub;
    });
  }

  private checkPermissions(): Observable<PermissionState> {
    return Capacitor.isNativePlatform()
      ? from(PushNotifications.checkPermissions()).pipe(map((result) => result.receive))
      : of<PermissionState>('denied');
  }

  private requestPermissions(): Observable<PermissionState> {
    return Capacitor.isNativePlatform()
      ? from(PushNotifications.requestPermissions()).pipe(map((result) => result.receive))
      : of<PermissionState>('denied');
  }

  private register(): Observable<string | null> {
    return new Observable<string | null>((subscriber) => {
      const handles = [
        PushNotifications.addListener('registration', (token) => {
          subscriber.next(token.value);
          subscriber.complete();
        }),
        PushNotifications.addListener('registrationError', (err) => {
          subscriber.error(err);
        }),
      ];

      PushNotifications.register().catch((err) => {
        subscriber.error(err);
      });

      return () => {
        for (const handle of handles) {
          handle.remove();
        }
      };
    }).pipe(
      // 안드로이드에서 FCM.getToken()이 정상적으로 작동하지 않으므로 iOS에서만 호출
      // https://github.com/capacitor-community/fcm/issues/99
      concatMap((token) => (Capacitor.getPlatform() === 'ios' ? FCM.getToken().then((result) => result.token) : of(token)))
    );
  }

  /**
   * 알림 채널의 존재 여부를 확인하고, 존재하지 않으면 알림 채널을 생성합니다.
   * 안드로이드 앱 환경에서만 작동하며, 다른 환경에서는 바로 반환됩니다.
   */
  private async ensureChannel(): Promise<void> {
    if (Capacitor.getPlatform() !== 'android') {
      return;
    }

    try {
      const { channels } = await PushNotifications.listChannels();

      const channelFuncs = {
        common: () =>
          PushNotifications.createChannel({
            id: 'common',
            name: '일반 알림',
            description: '일반 알림',
            importance: 4,
            lights: true,
            lightColor: '#e9334a',
            vibration: true,
          }),
      };

      await Promise.all(
        Object.entries(channelFuncs)
          .map(([id, func]) =>
            channels.some((channel) => channel.id === id)
              ? null
              : func().catch((err) => {
                  console.error(err);
                })
          )
          .filter(isNotNullish)
      );
    } catch (err) {
      console.error(err);
    }
  }
}
