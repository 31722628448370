import { Observable, isObservable, map, pairwise, startWith } from 'rxjs';

export const fromResize = (
  target: Element | Array<Element> | Observable<Element | Array<Element>>,
  options?: ResizeObserverOptions
): Observable<Array<ResizeObserverEntry>> =>
  new Observable<Array<ResizeObserverEntry>>((subscriber) => {
    const ro = new ResizeObserver((entries) => {
      subscriber.next(entries);
    });

    if (isObservable(target)) {
      const sub = target
        .pipe(
          map((elem) => (Array.isArray(elem) ? elem : [elem])),
          startWith([] as Array<Element>),
          pairwise()
        )
        .subscribe(([prev, curr]) => {
          for (let i = 0, len = curr.length; i < len; i += 1) {
            if (!prev.includes(curr[i])) {
              ro.observe(curr[i]);
            }
          }
          for (let i = 0, len = prev.length; i < len; i += 1) {
            if (!curr.includes(prev[i])) {
              ro.unobserve(prev[i]);
            }
          }
        });
      return () => {
        ro.disconnect();
        sub.unsubscribe();
      };
    }

    if (Array.isArray(target)) {
      for (const t of target) {
        ro.observe(t, options);
      }
    } else {
      ro.observe(target, options);
    }
    return () => {
      ro.disconnect();
    };
  });
