import { Injectable, OnDestroy } from '@angular/core';
import { Storage } from '@ionic/storage';
import { DateTime } from 'luxon';
import { Observable, Subject, defer, filter, map, mergeMap, of, race, take, tap } from 'rxjs';
import { User } from '../../../types/common';
import { completeAll } from '../../../utils/complete-all';
import { BaseService } from '../base.service';

interface UserCache {
  user: User;
  expiry: DateTime;
}

/** 사용자 정보 로컬 캐시 처리 도와주는 서비스 */
@Injectable({
  providedIn: 'root',
})
export class UserCacheService extends BaseService implements OnDestroy {
  /** 로딩 중인 사용자 ID 목록 */
  readonly storageLoadingUserIds: Array<string> = [];
  /** ID -> 사용자 캐시 맵, 이하 사용자 캐시 */
  readonly userCaches = new Map<string, UserCache>();
  /** 사용자명 -> ID 캐시 맵, 이하 사용자명 캐시 */
  readonly usernameCaches = new Map<string, string>();

  private readonly storageLoadSubject = new Subject<string>();
  private readonly storageLoadedSubject = new Subject<UserCache>();
  private readonly storageLoadMissSubject = new Subject<string>();

  constructor(private storage: Storage) {
    super();

    // 로컬 저장소 로드 요청 처리
    this.subscription = this.storageLoadSubject
      .pipe(
        filter((userId) => {
          if (this.storageLoadingUserIds.includes(userId)) {
            // 이미 로딩 중인 경우 스킵
            return false;
          } else {
            // 로딩 중인 사용자 ID 목록에 추가
            this.storageLoadingUserIds.push(userId);
            return true;
          }
        }),
        mergeMap((userId) =>
          // 로컬 저장소에서 로드
          this.storage.get(`celebhere.user.cache:${userId}`).then(
            (user) => [userId, user as User | null, undefined] as const,
            (err) => [userId, null, err] as const
          )
        ),
        tap(([userId, user, err]) => {
          // 로딩 중인 사용자 ID 목록에서 제외
          const index = this.storageLoadingUserIds.indexOf(userId);
          if (index > -1) {
            this.storageLoadingUserIds.splice(index, 1);
          }

          if (err == null && user != null) {
            // 사용자명이 있는 경우 이전 사용자명 -> ID 캐시 삭제
            if (user.celebUsername) {
              const prevUser = this.userCaches.get(user.id);
              const prevUsername = prevUser?.user.celebUsername ?? undefined;
              if (prevUsername != null && prevUsername !== (user.celebUsername ?? undefined)) {
                this.usernameCaches.delete(prevUsername);
              }
            }

            // ID -> 사용자 캐시 업데이트
            const userCache = { user, expiry: DateTime.local() };
            this.userCaches.set(user.id, userCache);

            // 사용자명이 있는 경우 사용자명 -> ID 캐시 업데이트
            if (user.celebUsername) {
              this.usernameCaches.set(user.celebUsername, user.id);
            }

            // 오류 없으면 성공 이벤트 방출
            this.storageLoadedSubject.next(userCache);
          } else {
            // 오류 있으면 실패 이벤트 방출
            if (err != null) {
              console.error(err);
            }
            this.storageLoadMissSubject.next(userId);
          }
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    completeAll([this.storageLoadSubject, this.storageLoadedSubject, this.storageLoadMissSubject]);
    this.storageLoadingUserIds.length = 0;
    this.userCaches.clear();
  }

  /**
   * 메모리 내 사용자 캐시 가져오기
   *
   * 메모리에 있지만 {@link UserCache.expiry expiry}가 지난 경우 또는 로컬 저장소에 있으나 메모리에 없는 경우 `null`임
   */
  getUser(userId: string): User | null {
    const userCache = this.userCaches.get(userId);
    return userCache != null && userCache.expiry > DateTime.local() ? userCache.user : null;
  }

  /** 사용자 캐시 추가 */
  setUser(user: User, expiry: DateTime = DateTime.local()): void {
    // 메모리에 추가
    this.userCaches.set(user.id, {
      user: user,
      expiry,
    });
    // 로컬 저장소에 저장
    this.storage.set(`celebhere.user.cache:${user.id}`, user);

    // 사용자명 있는 경우 사용자명 캐시 업데이트
    if (user.celebUsername) {
      // 사용자명이 변경된 경우 이전 사용자명 캐시 삭제
      const prevUser = this.userCaches.get(user.id);
      const prevUsername = prevUser?.user.celebUsername ?? undefined;
      if (prevUsername != null && prevUsername !== (user.celebUsername ?? undefined)) {
        this.usernameCaches.delete(prevUsername);
      }
      // 사용자명 캐시 추가
      this.usernameCaches.set(user.celebUsername, user.id);
      this.storage.set(`celebhere.username.cache:${user.celebUsername}`, user.id);
    }
  }

  /** 사용자 캐시 삭제 */
  deleteUser(userId: string): void {
    this.userCaches.delete(userId);
    this.storage.remove(`celebhere.user.cache:${userId}`);
  }

  /**
   * 사용자 캐시 가져오기
   *
   * {@link getUser}와 다르게 메모리에 없는 경우 로컬 저장소에서 불러오는 걸 기다리며, {@link UserCache.expiry expiry} 값을 확인하지 않음
   */
  fetchUser(userId: string): Observable<UserCache | null> {
    return defer(() => {
      const userCache = this.userCaches.get(userId);

      if (userCache != null) {
        return of(userCache);
      }

      return new Observable<UserCache | null>((subscriber) => {
        // 성공과 실패 중 더 빠른 값 방출
        const sub = race(
          // ID 일치하는 로드 성공 이벤트 발생 시 UserCache
          this.storageLoadedSubject.pipe(
            filter(({ user }) => user.id === userId),
            take(1)
          ),
          // ID 일치하는 로드 실패 이벤트 발생 시 null
          this.storageLoadMissSubject.pipe(
            filter((userId2) => userId2 === userId),
            take(1),
            map(() => null)
          )
        ).subscribe(subscriber);

        // 로컬 저장소 로드 요청
        this.storageLoadSubject.next(userId);

        return sub;
      });
    });
  }

  /** 사용자명 캐시 가져오기 */
  getUsername(username: string): string | null {
    return this.usernameCaches.get(username) ?? null;
  }

  /** 사용자명 캐시 삭제 */
  deleteUsername(username: string): void {
    this.usernameCaches.delete(username);
  }
}
