import { Injectable, OnDestroy } from '@angular/core';
import { App, AppInfo } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import {
  BehaviorSubject,
  EMPTY,
  Observable,
  Subject,
  catchError,
  concatMap,
  finalize,
  map,
  of,
  race,
  shareReplay,
  skip,
  switchMap,
  take,
  throwError,
} from 'rxjs';
import { APP_BUILD, APP_VERSION } from '../../../../version';
import { APIErrorCode } from '../../../constants/api-error-code';
import { GetNoticeVersionResult } from '../../../types/api/notice';
import { completeAll } from '../../../utils/complete-all';
import { isAPIErrorWithCode } from '../../../utils/is-api-error-with-code';
import { tryParseInt } from '../../../utils/try-parse-int';
import { ApiService } from '../api/api.service';
import { BaseService } from '../base.service';
import { ClientVersionInfo, ServerVersionInfo, VersionInfo } from './version.type';

type Nullable<T> = {
  [K in keyof T]: T[K] | null;
};

type VersionInfoInit = Pick<VersionInfo, 'minVersion' | 'minVersionName' | 'latestVersion' | 'latestVersionName'>;

@Injectable({ providedIn: 'root' })
export class VersionService extends BaseService implements OnDestroy {
  get version() {
    return this.makeVersionInfo(this._clientVersion, this.serverVersionSubject.value);
  }

  readonly version$: Observable<VersionInfo>;
  readonly isRefreshing$: Observable<boolean>;

  private _clientVersion: ClientVersionInfo = { currentVersion: null, currentVersionName: null };

  private readonly serverVersionSubject = new BehaviorSubject<ServerVersionInfo>({
    minVersion: null,
    minVersionName: null,
    latestVersion: null,
    latestVersionName: null,
  });
  private readonly isRefreshingSubject = new BehaviorSubject<boolean>(false);

  private readonly refreshSubject = new Subject<void>();
  private readonly refreshErrorSubject = new Subject<any>();

  constructor(private apiService: ApiService) {
    super();

    this.version$ = this.serverVersionSubject.pipe(
      map((serverVersion) => this.makeVersionInfo(this._clientVersion, serverVersion)),
      shareReplay(1)
    );
    this.isRefreshing$ = this.isRefreshingSubject.asObservable();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    completeAll([this.serverVersionSubject, this.isRefreshingSubject, this.refreshSubject, this.refreshErrorSubject]);
  }

  async init(versionInfoInit: VersionInfoInit): Promise<void> {
    const appInfo = Capacitor.isNativePlatform()
      ? await App.getInfo()
      : ({
          name: null,
          id: null,
          build: APP_BUILD,
          version: APP_VERSION,
        } satisfies Nullable<AppInfo>);

    this._clientVersion = {
      currentVersion: tryParseInt(appInfo.build),
      currentVersionName: appInfo.version,
    };

    this.serverVersionSubject.next({
      minVersion: versionInfoInit.minVersion,
      minVersionName: versionInfoInit.minVersionName,
      latestVersion: versionInfoInit.latestVersion,
      latestVersionName: versionInfoInit.latestVersionName,
    });

    this.subscription = this.refreshSubject
      .pipe(
        switchMap(() => {
          this.isRefreshingSubject.next(true);

          return this.apiService.getNoticeVersion({}).pipe(
            map((res) => res.result),
            catchError((err) => {
              if (isAPIErrorWithCode(err, APIErrorCode.UPDATE_REQUIRED)) {
                return of({
                  minVersion: err.data?.minVersion ?? null,
                  minVersionName: err.data?.minVersionName ?? null,
                  latestVersion: err.data?.latestVersion ?? null,
                  latestVersionName: err.data?.latestVersionName ?? null,
                } satisfies GetNoticeVersionResult);
              }
              return throwError(() => err);
            }),
            catchError((err) => {
              this.refreshErrorSubject.next(err);
              return EMPTY;
            }),
            finalize(() => this.isRefreshingSubject.next(false))
          );
        })
      )
      .subscribe((serverVersion) => {
        this.serverVersionSubject.next(serverVersion);
      });
  }

  refresh(): Observable<void> {
    return new Observable<void>((subscriber) => {
      const sub = race(
        this.serverVersionSubject.pipe(
          skip(1),
          take(1),
          map(() => undefined)
        ),
        this.refreshErrorSubject.pipe(
          take(1),
          concatMap((err) => throwError(() => err))
        )
      ).subscribe(subscriber);

      this.refreshSubject.next();

      return sub;
    });
  }

  private makeVersionInfo(client: ClientVersionInfo, server: ServerVersionInfo): VersionInfo {
    return {
      ...client,
      ...server,
      updateRequired: client.currentVersion != null && server.minVersion != null && client.currentVersion < server.minVersion,
      updateAvailable: client.currentVersion != null && server.latestVersion != null && client.currentVersion < server.latestVersion,
    };
  }
}
