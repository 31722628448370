import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { TranslateService } from '@ngx-translate/core';
import 'cordova-plugin-purchase';
import {
  BehaviorSubject,
  EMPTY,
  Observable,
  Subject,
  catchError,
  concatMap,
  defer,
  exhaustMap,
  filter,
  firstValueFrom,
  from,
  map,
  of,
  race,
  take,
  tap,
  throwError,
} from 'rxjs';
import { completeAll } from '../../../utils/complete-all';
import { runInZone } from '../../../utils/run-in-zone';
import { BaseService } from '../base.service';

@Injectable({
  providedIn: 'root',
})
export class InAppPurchaseService extends BaseService implements OnDestroy {
  get localTransactions(): Array<CdvPurchase.Transaction> {
    return this.isPluginAvailable ? CdvPurchase.store.localTransactions : [];
  }

  get localReceipts(): Array<CdvPurchase.Receipt> {
    return this.isPluginAvailable ? CdvPurchase.store.localReceipts : [];
  }

  get products(): Array<CdvPurchase.Product> {
    return this.isPluginAvailable ? CdvPurchase.store.products : [];
  }

  readonly ErrorCode = 'CdvPurchase' in window ? CdvPurchase.ErrorCode : ({} as typeof CdvPurchase.ErrorCode);
  readonly Platform = 'CdvPurchase' in window ? CdvPurchase.Platform : ({} as typeof CdvPurchase.Platform);
  readonly TransactionState = 'CdvPurchase' in window ? CdvPurchase.TransactionState : ({} as typeof CdvPurchase.TransactionState);

  readonly isPluginAvailable: boolean;

  readonly error$: Observable<CdvPurchase.IError>;
  readonly receiptUpdated$: Observable<CdvPurchase.Receipt>;
  readonly productUpdated$: Observable<CdvPurchase.Product>;
  readonly approved$: Observable<CdvPurchase.Transaction>;
  readonly finished$: Observable<CdvPurchase.Transaction>;
  readonly verified$: Observable<CdvPurchase.VerifiedReceipt>;
  readonly unverified$: Observable<CdvPurchase.UnverifiedReceipt>;

  private cdvPurchaseListenerRemove?: Function;

  private readonly initSubject = new Subject<void>();
  private readonly initializedSubject = new BehaviorSubject<boolean>(false);
  private readonly errorSubject = new Subject<CdvPurchase.IError>();
  private readonly receiptUpdatedSubject = new Subject<CdvPurchase.Receipt>();
  private readonly productUpdatedSubject = new Subject<CdvPurchase.Product>();
  private readonly approvedSubject = new Subject<CdvPurchase.Transaction>();
  private readonly finishedSubject = new Subject<CdvPurchase.Transaction>();
  private readonly verifiedSubject = new Subject<CdvPurchase.VerifiedReceipt>();
  private readonly unverifiedSubject = new Subject<CdvPurchase.UnverifiedReceipt>();
  private readonly updateSubject = new Subject<void>();
  private readonly updateDoneSubject = new Subject<void>();
  private readonly updateErrorSubject = new Subject<any>();

  constructor(private ngZone: NgZone, private translateService: TranslateService) {
    super();

    this.isPluginAvailable = Capacitor.getPlatform() === 'ios';

    this.error$ = this.errorSubject.asObservable();
    this.receiptUpdated$ = this.receiptUpdatedSubject.asObservable();
    this.productUpdated$ = this.productUpdatedSubject.asObservable();
    this.approved$ = this.approvedSubject.asObservable();
    this.finished$ = this.finishedSubject.asObservable();
    this.verified$ = this.verifiedSubject.asObservable();
    this.unverified$ = this.unverifiedSubject.asObservable();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    completeAll([
      this.initSubject,
      this.initializedSubject,
      this.receiptUpdatedSubject,
      this.productUpdatedSubject,
      this.approvedSubject,
      this.finishedSubject,
      this.verifiedSubject,
      this.updateSubject,
      this.updateDoneSubject,
      this.updateErrorSubject,
    ]);
    this.cdvPurchaseListenerRemove?.();
  }

  /** 2번 이상 호출하지 않도록 주의 */
  async init(): Promise<void> {
    await firstValueFrom(
      defer(() => {
        if (!this.isPluginAvailable) {
          return of(undefined);
        }

        CdvPurchase.store.verbosity = CdvPurchase.LogLevel.DEBUG;

        const errorListener = (ev: CdvPurchase.IError) => this.errorSubject.next(ev);
        const receiptUpdatedListener = (ev: CdvPurchase.Receipt) => this.receiptUpdatedSubject.next(ev);
        const productUpdatedListener = (ev: CdvPurchase.Product) => this.productUpdatedSubject.next(ev);
        const approvedListener = (ev: CdvPurchase.Transaction) => this.approvedSubject.next(ev);
        const finishedListener = (ev: CdvPurchase.Transaction) => this.finishedSubject.next(ev);
        const verifiedListener = (ev: CdvPurchase.VerifiedReceipt) => this.verifiedSubject.next(ev);
        const unverifiedListener = (ev: CdvPurchase.UnverifiedReceipt) => this.unverifiedSubject.next(ev);

        CdvPurchase.store.error(errorListener);
        CdvPurchase.store
          .when()
          .receiptUpdated(receiptUpdatedListener)
          .productUpdated(productUpdatedListener)
          .approved(approvedListener)
          .finished(finishedListener)
          .verified(verifiedListener)
          .unverified(unverifiedListener);

        this.cdvPurchaseListenerRemove = () => {
          CdvPurchase.store.off(errorListener);
          CdvPurchase.store.off(receiptUpdatedListener);
          CdvPurchase.store.off(productUpdatedListener);
          CdvPurchase.store.off(approvedListener);
          CdvPurchase.store.off(finishedListener);
          CdvPurchase.store.off(verifiedListener);
          CdvPurchase.store.off(unverifiedListener);
        };

        return Promise.all([
          CdvPurchase.store.initialize(),
          new Promise((resolve) => {
            CdvPurchase.store.ready(resolve);
          }),
        ]);
      })
    );

    this.subscription = this.updateSubject
      .pipe(
        exhaustMap(() => {
          const defaultPlatform = CdvPurchase.store.defaultPlatform();
          return from(
            CdvPurchase.store.getAdapter(defaultPlatform) == null ? CdvPurchase.store.initialize([defaultPlatform]) : CdvPurchase.store.update()
          ).pipe(
            tap(() => {
              this.updateDoneSubject.next();
            }),
            catchError((err) => {
              this.updateErrorSubject.next(err);
              return EMPTY;
            })
          );
        })
      )
      .subscribe();
  }

  register(product: CdvPurchase.IRegisterProduct | Array<CdvPurchase.IRegisterProduct>): void {
    CdvPurchase.store.register(product);
  }

  update(): Observable<void> {
    return new Observable<void>((subscriber) => {
      const sub = race(
        this.updateDoneSubject.pipe(
          take(1),
          map(() => undefined)
        ),
        this.updateErrorSubject.pipe(
          take(1),
          concatMap((err) => throwError(() => err))
        )
      )
        .pipe(runInZone(this.ngZone))
        .subscribe(subscriber);

      this.updateSubject.next();

      return sub;
    });
  }

  /** IAP 결제 호출 */
  order(productOrOffer: CdvPurchase.Product | CdvPurchase.Offer, additionalData?: CdvPurchase.AdditionalData): Observable<CdvPurchase.Transaction> {
    return defer(() => {
      const offer = 'getOffer' in productOrOffer ? productOrOffer.getOffer() : productOrOffer;

      if (offer == null) {
        throw new Error(this.translateService.instant('STORE.ERR_CANNOT_BUY_PRODUCT'));
      }

      CdvPurchase.store.order(offer, additionalData);

      return race(
        this.approvedSubject.pipe(filter((transaction) => transaction.products[0].id === offer.productId)),
        this.errorSubject.pipe(concatMap((err) => throwError(() => err)))
      ).pipe(take(1));
    });
  }
}
