import { WebPlugin } from '@capacitor/core';
import { CertificationParams, CertificationResponse, RequestPayParams, RequestPayResponse } from '../../types/iamport';
import type { IamportPlugin } from './definitions';

export class IamportWeb extends WebPlugin implements IamportPlugin {
  init(params: { user_code: string }): void {
    throw new Error('Method not implemented.');
  }
  certification(params: CertificationParams): Promise<CertificationResponse> {
    throw new Error('Method not implemented.');
  }
  requestPay(params: RequestPayParams): Promise<RequestPayResponse> {
    throw new Error('Method not implemented.');
  }
}
