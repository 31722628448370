import { AlertButton as IonicAlertButton, AlertInput as IonicAlertInput, AlertOptions as IonicAlertOptions } from '@ionic/angular';
import { Color } from '../color';

export interface AlertButton extends Pick<IonicAlertButton, 'text' | 'role' | 'cssClass' | 'handler'> {
  color?: Color;
  fill?: 'clear' | 'outline' | 'solid' | 'default';
}

export interface AlertInput extends Pick<IonicAlertInput, 'type' | 'name' | 'placeholder' | 'value' | 'label' | 'disabled' | 'min' | 'max'> {}

export interface AlertOptions extends Pick<IonicAlertOptions, 'header' | 'message' | 'cssClass'> {
  inputs?: AlertInput[];
  buttons?: (AlertButton | string)[];
}

export interface AlertComponentOptions {
  header?: string;
  message?: string;
  inputs?: Array<AlertInput>;
  buttons?: Array<AlertButton>;
}
