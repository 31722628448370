import { Injectable, OnDestroy } from '@angular/core';
import { Subscription, SubscriptionLike } from 'rxjs';

@Injectable()
export class BaseService implements OnDestroy {
  private _subscriptions: Subscription;

  constructor() {
    this._subscriptions = new Subscription();
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  set subscription(value: SubscriptionLike) {
    this._subscriptions.add(value);
  }
}
