import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';

/** 비로그인 상태인 경우 이동 방지 */
export const authGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const authService = inject(AuthService);

  if (!authService.authed) {
    const extras = router.getCurrentNavigation()?.extras;

    router.navigate(['/start'], {
      queryParams: { r: state.url },
      state: { rState: router.getCurrentNavigation()?.extras.state },
      skipLocationChange: extras?.skipLocationChange,
      replaceUrl: extras?.replaceUrl,
    });
    return false;
  }
  return true;
};
