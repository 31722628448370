import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { BootService } from '../../services/boot/boot.service';
import { VersionService } from '../../services/version/version.service';

/** 앱 부팅 및 버전 확인 전 페이지 이동 방지 */
export const bootGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const bootService = inject(BootService);
  const versionService = inject(VersionService);

  // 앱 부팅 확인
  if (bootService.bootState !== 'done') {
    router.navigate(['/boot'], {
      queryParams: { r: state.url },
      state: { rState: router.getCurrentNavigation()?.extras.state },
      skipLocationChange: true,
    });
    return false;
  }
  // 업데이트 필요 확인
  if (versionService.version.updateRequired && !state.url.startsWith('/version')) {
    const extras = router.getCurrentNavigation()?.extras;

    router.navigate(['/version'], {
      queryParams: { r: state.url },
      state: { rState: router.getCurrentNavigation()?.extras.state },
      skipLocationChange: extras?.skipLocationChange,
      replaceUrl: extras?.replaceUrl,
    });
    return false;
  }
  return true;
};
