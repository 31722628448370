import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';

/** 로그인 상태인 경우 이동 방지 */
export const nonAuthGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const authService = inject(AuthService);

  if (authService.authed) {
    const extras = router.getCurrentNavigation()?.extras;

    const r = route.queryParamMap.get('r');
    if (r != null) {
      router.navigateByUrl(r, {
        skipLocationChange: extras?.skipLocationChange,
        replaceUrl: extras?.replaceUrl,
      });
    } else {
      router.navigate(['/tabs'], {
        queryParams: { r: state.url },
        state: { rState: extras?.state },
        skipLocationChange: extras?.skipLocationChange,
        replaceUrl: extras?.replaceUrl,
      });
    }
    return false;
  }
  return true;
};
