import { Injectable } from '@angular/core';
import { PrivacyScreen } from '@capacitor-community/privacy-screen';
import { Capacitor } from '@capacitor/core';
import { BehaviorSubject, NEVER, Observable } from 'rxjs';
import { BaseService } from '../base.service';

@Injectable({
  providedIn: 'root',
})
export class PrivacyScreenService extends BaseService {
  get enabled(): boolean {
    return this.enabledSubject.value;
  }

  readonly enabled$: Observable<boolean>;

  readonly screenRecordingStarted$: Observable<void>;
  readonly screenRecordingStopped$: Observable<void>;
  readonly screenshotTaken$: Observable<void>;

  private readonly enabledSubject = new BehaviorSubject<boolean>(Capacitor.isNativePlatform());

  constructor() {
    super();

    this.enabled$ = this.enabledSubject.asObservable();

    if (Capacitor.isNativePlatform()) {
      this.screenRecordingStarted$ = new Observable<void>((subscriber) => {
        const handle = PrivacyScreen.addListener('screenRecordingStarted', () => {
          subscriber.next();
        });

        return () => {
          handle.remove();
        };
      });

      this.screenRecordingStopped$ = new Observable<void>((subscriber) => {
        const handle = PrivacyScreen.addListener('screenRecordingStopped', () => {
          subscriber.next();
        });

        return () => {
          handle.remove();
        };
      });

      this.screenshotTaken$ = new Observable<void>((subscriber) => {
        const handle = PrivacyScreen.addListener('screenshotTaken', () => {
          subscriber.next();
        });

        return () => {
          handle.remove();
        };
      });
    } else {
      this.screenRecordingStarted$ = NEVER;
      this.screenRecordingStopped$ = NEVER;
      this.screenshotTaken$ = NEVER;
    }
  }

  async enable(): Promise<void> {
    await PrivacyScreen.enable();
    this.enabledSubject.next(true);
  }

  async disable(): Promise<void> {
    await PrivacyScreen.disable();
    this.enabledSubject.next(false);
  }
}
