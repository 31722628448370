import { Injectable, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, EMPTY, Observable, catchError, concat, concatMap, map, merge, switchMap, take, tap, throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { PaymentMethod } from '../../../constants/payment-method';
import {
  GetSubscriptionProductQueryParams,
  PostSubscriptionCancelSubscriptionPremiumParams,
  PostSubscriptionRequestTestSubscriptionPremiumResult,
  PostSubscriptionRestartSubscriptionPremiumParams,
} from '../../../types/api/subscription';
import { Subscription, SubscriptionOrder, SubscriptionPremium, SubscriptionProduct } from '../../../types/common';
import { RequestPayParams } from '../../../types/iamport';
import { completeAll } from '../../../utils/complete-all';
import { ApiService } from '../api/api.service';
import { BaseService } from '../base.service';
import { IamportService } from '../iamport/iamport.service';
import { SocketService } from '../socket/socket.service';
import { UserService } from '../user/user.service';

interface InitData {
  subscriptions: Array<Subscription> | null;
  subscriptionPremiums: Array<SubscriptionPremium> | null;
}

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService extends BaseService implements OnDestroy {
  readonly subscriptionList$: Observable<Array<Subscription>>;
  readonly subscriptionPremiumList$: Observable<Array<SubscriptionPremium>>;

  private readonly subscriptionListSubject = new BehaviorSubject<Array<Subscription>>([]);
  private readonly subscriptionPremiumListSubject = new BehaviorSubject<Array<SubscriptionPremium>>([]);

  constructor(
    private translateService: TranslateService,
    private apiService: ApiService,
    private iamportService: IamportService,
    private socketService: SocketService,
    private userService: UserService
  ) {
    super();

    this.subscriptionList$ = this.subscriptionListSubject.asObservable();
    this.subscriptionPremiumList$ = this.subscriptionPremiumListSubject.asObservable();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    completeAll([this.subscriptionListSubject, this.subscriptionPremiumListSubject]);
  }

  /** 2번 이상 호출하지 않도록 주의 */
  async init(initData: InitData | null): Promise<void> {
    this.subscriptionListSubject.next(initData?.subscriptions ?? []);
    this.subscriptionPremiumListSubject.next(initData?.subscriptionPremiums ?? []);

    this.subscription = this.userService.me$
      .pipe(
        switchMap((userMe, index) => {
          if (index > 0) {
            this.subscriptionListSubject.next([]);
          }

          if (userMe == null) {
            return EMPTY;
          }

          return concat(
            index > 0
              ? this.apiService
                  .getSubscriptionList({})
                  .pipe(
                    map((res) => res.result.subscriptions),
                    catchError((err) => {
                      console.error(err);
                      return EMPTY;
                    })
                  )
                  .pipe(
                    tap((subscriptions) => {
                      this.subscriptionListSubject.next(subscriptions);
                    })
                  )
              : EMPTY,
            merge(
              this.socketService.fromEvent('subscriptionAdd').pipe(map((ev) => ({ evName: 'subscriptionAdd', ev }))),
              this.socketService.fromEvent('subscriptionRemove').pipe(map((ev) => ({ evName: 'subscriptionRemove', ev })))
            ).pipe(
              tap(({ evName, ev }) => {
                const newSubscriptionList = this.subscriptionListSubject.value.filter((subscription) => subscription.id !== ev.subscription.id);
                if (evName === 'subscriptionAdd') {
                  newSubscriptionList.push(ev.subscription);
                }
                this.subscriptionListSubject.next(newSubscriptionList);
              })
            )
          );
        })
      )
      .subscribe();

    this.subscription = this.userService.me$
      .pipe(
        switchMap((userMe, index) => {
          if (index > 0) {
            this.subscriptionPremiumListSubject.next([]);
          }

          if (userMe == null) {
            return EMPTY;
          }

          return concat(
            index > 0
              ? this.apiService
                  .getSubscriptionPremiumList({})
                  .pipe(
                    map((res) => res.result.subscriptionPremiums),
                    catchError((err) => {
                      console.error(err);
                      return EMPTY;
                    })
                  )
                  .pipe(
                    tap((subscriptionPremiums) => {
                      this.subscriptionPremiumListSubject.next(subscriptionPremiums);
                    })
                  )
              : EMPTY,
            merge(
              this.socketService.fromEvent('subscriptionPremiumAdd').pipe(map((ev) => ({ evName: 'subscriptionPremiumAdd', ev }))),
              this.socketService.fromEvent('subscriptionPremiumRemove').pipe(map((ev) => ({ evName: 'subscriptionPremiumRemove', ev }))),
              this.socketService.fromEvent('subscriptionPremiumUpdate').pipe(map((ev) => ({ evName: 'subscriptionPremiumUpdate', ev })))
            ).pipe(
              tap(({ evName, ev }) => {
                const newSubscriptionPremiumList = this.subscriptionPremiumListSubject.value.filter(
                  (subscriptionPremium) => subscriptionPremium.id !== ev.subscriptionPremium.id
                );
                if (evName === 'subscriptionPremiumAdd' || evName === 'subscriptionPremiumUpdate') {
                  newSubscriptionPremiumList.push(ev.subscriptionPremium);
                }
                this.subscriptionPremiumListSubject.next(newSubscriptionPremiumList);
              })
            )
          );
        })
      )
      .subscribe();
  }

  getSubscriptionList(celebId: string): boolean {
    return this.subscriptionListSubject.value.some((subscription) => subscription.celebUserId === celebId);
  }

  getSubscriptionPremiumList(celebId: string): boolean {
    return this.subscriptionPremiumListSubject.value.some((subscription) => subscription.celebUserId === celebId);
  }

  getSubscriptionPremiumListByUser(userMeId: string, celebId: string): boolean {
    return this.subscriptionPremiumListSubject.value.some((subscription) => subscription.userId === userMeId && subscription.celebUserId === celebId);
  }

  getSubscriptionProduct(params: GetSubscriptionProductQueryParams): Observable<SubscriptionProduct> {
    return this.apiService.getSubscriptionProduct(params).pipe(map((res) => res.result.subscriptionProduct));
  }

  listSubscriptionProducts(): Observable<Array<SubscriptionProduct>> {
    return this.apiService.getSubscriptionProductList({}).pipe(map((res) => res.result.subscriptionProducts));
  }

  subscribeWithCard(subscriptionProduct: SubscriptionProduct): Observable<SubscriptionPremium> {
    return this.userService.me$.pipe(
      take(1),
      concatMap((userMe) =>
        userMe != null
          ? this.apiService
              .postSubscriptionOrder({
                productId: subscriptionProduct.id,
                paymentMethod: PaymentMethod.card,
              })
              .pipe(map((res) => ({ userMe, res })))
          : throwError(() => new Error(this.translateService.instant('ERROR.SIGN_IN_REQUIRED')))
      ),
      concatMap(({ userMe, res }) => {
        const { orderId, paymentId, customerUid: billingKeyId } = res.result;

        const paymentData: RequestPayParams = {
          pg: environment.iamport.danal,
          pay_method: 'card',
          name: subscriptionProduct.name,
          merchant_uid: orderId,
          amount: subscriptionProduct.krwAmountTotal,
          buyer_name: userMe.nickname,
          buyer_tel: '',
          buyer_email: '', // TODO: 사용자 이메일
          customer_uid: billingKeyId,
          custom_data: {
            userId: userMe.id,
            paymentId,
          },
        };

        return this.iamportService.requestPay(paymentData).pipe(
          map((res) => ({
            orderId,
            paymentId,
            res,
          }))
        );
      }),
      concatMap(({ orderId, paymentId, res }) =>
        this.apiService
          .postSubscriptionOrderComplete({
            orderId,
            paymentId,
            transactionId: res.imp_uid,
            error: !res.success
              ? {
                  code: res.error_code,
                  message: res.error_msg,
                }
              : undefined,
          })
          .pipe(map((res) => res.result.subscriptionPremium))
      )
    );
  }
  subscribeWithCardTest(subscriptionProduct: SubscriptionProduct): Observable<SubscriptionPremium> {
    return this.userService.me$.pipe(
      take(1),
      concatMap((userMe) =>
        userMe != null
          ? this.apiService
              .postSubscriptionOrder({
                productId: subscriptionProduct.id,
                paymentMethod: PaymentMethod.cardTest,
              })
              .pipe(map((res) => ({ userMe, res })))
          : throwError(() => new Error(this.translateService.instant('ERROR.SIGN_IN_REQUIRED')))
      ),
      concatMap(({ userMe, res }) => {
        const { orderId, paymentId, customerUid: billingKeyId } = res.result;

        const paymentData: RequestPayParams = {
          pg: environment.iamport.danalTest,
          pay_method: 'card',
          name: subscriptionProduct.name,
          merchant_uid: orderId,
          amount: subscriptionProduct.krwAmountTotal,
          buyer_name: userMe.nickname,
          buyer_tel: '',
          buyer_email: '', // TODO: 사용자 이메일
          customer_uid: billingKeyId,
          custom_data: {
            userId: userMe.id,
            paymentId,
          },
        };

        return this.iamportService.requestPay(paymentData).pipe(
          map((res) => ({
            orderId,
            paymentId,
            res,
          }))
        );
      }),
      concatMap(({ orderId, paymentId, res }) =>
        this.apiService
          .postSubscriptionOrderComplete({
            orderId,
            paymentId,
            transactionId: res.imp_uid,
            error: !res.success
              ? {
                  code: res.error_code,
                  message: res.error_msg,
                }
              : undefined,
          })
          .pipe(map((res) => res.result.subscriptionPremium))
      )
    );
  }

  subscribeWithUserPaymentMethod(subscriptionProduct: SubscriptionProduct, userPaymentMethodId: string): Observable<SubscriptionPremium> {
    return this.userService.me$.pipe(
      take(1),
      concatMap((userMe) =>
        userMe != null
          ? this.apiService
              .postSubscriptionOrderUserPaymentMethod({
                productId: subscriptionProduct.id,
                userPaymentMethodId,
              })
              .pipe(map((res) => res.result.subscriptionPremium))
          : throwError(() => new Error(this.translateService.instant('ERROR.SIGN_IN_REQUIRED')))
      )
    );
  }

  cancelSubscriptionPremium(params: PostSubscriptionCancelSubscriptionPremiumParams): Observable<SubscriptionPremium> {
    return this.apiService.postSubscriptionCancelSubscriptionPremium(params).pipe(map((res) => res.result.subscriptionPremium));
  }

  restartSubscriptionPremium(params: PostSubscriptionRestartSubscriptionPremiumParams): Observable<SubscriptionPremium> {
    return this.apiService.postSubscriptionRestartSubscriptionPremium(params).pipe(map((res) => res.result.subscriptionPremium));
  }

  orderHistory(celebUserId: string): Observable<Array<SubscriptionOrder>> {
    return this.apiService.getSubscriptionOrderHistory({ celebUserId }).pipe(map((res) => res.result.orders));
  }

  requestTestSubscriptionPremium(celebUserIdOrUsername: string): Observable<PostSubscriptionRequestTestSubscriptionPremiumResult> {
    return this.apiService
      .postSubscriptionRequestTestSubscription({
        celebUserIdOrUsername,
      })
      .pipe(map((res) => res.result));
  }
}
