import { Injectable, OnDestroy } from '@angular/core';
import { Storage } from '@ionic/storage';
import { BehaviorSubject, Observable, skip } from 'rxjs';
import { completeAll } from '../../../utils/complete-all';
import { tryJSONParse } from '../../../utils/try-json-parse';
import { BaseService } from '../base.service';

enum StorageKey {
  token = 'celebhere.auth.token',
}

/**
 * 앱 실행 중 액세스 토큰을 한 곳에서 관리하기 위한 서비스
 *
 * 로컬 저장소에 저장 및 불러오기도 담당
 */
@Injectable({
  providedIn: 'root',
})
export class AccessTokenService extends BaseService implements OnDestroy {
  get accessToken(): string | null {
    return this.accessTokenSubject.value;
  }
  set accessToken(value: string | null) {
    this.accessTokenSubject.next(value);
  }
  get userId(): string | null {
    return this.userIdSubject.value;
  }

  readonly accessToken$: Observable<string | null>;
  readonly userId$: Observable<string | null>;

  private readonly accessTokenSubject = new BehaviorSubject<string | null>(null);
  private readonly userIdSubject = new BehaviorSubject<string | null>(null);

  constructor(private storage: Storage) {
    super();

    this.accessToken$ = this.accessTokenSubject.asObservable();
    this.userId$ = this.userIdSubject.asObservable();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();

    completeAll([this.accessTokenSubject, this.userIdSubject]);
  }

  async init(): Promise<void> {
    const accessToken = ((await this.storage.get(StorageKey.token)) as string | undefined) || null;

    this.accessTokenSubject.next(accessToken);

    // 토큰에 포함된 정보 가져오기
    this.subscription = this.accessTokenSubject.subscribe((accessToken) => {
      const jwtPayloadString = accessToken?.split('.')[1] ?? null;
      const jwtPayload = jwtPayloadString != null ? (tryJSONParse(atob(jwtPayloadString)) as { sub?: string }) : null;
      this.userIdSubject.next(jwtPayload?.sub ?? null);
    });

    // 로컬 저장소에 토큰 저장
    this.subscription = this.accessTokenSubject.pipe(skip(1)).subscribe((accessToken) => {
      if (accessToken) {
        this.storage.set(StorageKey.token, accessToken).catch((err) => console.error(err));
      } else {
        this.storage.remove(StorageKey.token).catch((err) => console.error(err));
      }
    });
  }
}
