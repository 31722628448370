import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { IonRouterOutlet } from '@ionic/angular';
import { delay, filter, take } from 'rxjs/operators';
import { AlertService } from '../alert';
import { BootService } from './common/services/boot/boot.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  @ViewChild(IonRouterOutlet, { static: true })
  ionRouterOutlet!: IonRouterOutlet;

  constructor(private alertService: AlertService, private bootService: BootService) {}

  ngOnInit(): void {
    this.bootService
      .boot({
        ionRouterOutlet: this.ionRouterOutlet,
      })
      .catch((err) => {
        this.alertService.alertError$(err).subscribe(() => {
          if (Capacitor.isNativePlatform()) {
            App.exitApp().catch((err) => {
              console.error(err);
            });
          } else {
            // TODO: 웹에서는 어떻게 할 것인가
          }
        });
      });

    this.hideSplashOnBoot();
  }

  private hideSplashOnBoot(): void {
    this.bootService.bootState$
      .pipe(
        filter((bootState) => bootState === 'done' || bootState === 'error'),
        take(1),
        delay(200)
      )
      .subscribe(() => {
        SplashScreen.hide();
      });
  }
}
