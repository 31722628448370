import { LocationStrategy } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Capacitor, CapacitorException } from '@capacitor/core';
import { EMPTY, Observable, catchError, concatMap, from, throwError } from 'rxjs';
import { KakaoLogin } from '../../../plugins/kakao-login';
import { AuthService } from '../auth/auth.service';
import { KakaoLoginWebService } from '../kakao-login-web/kakao-login-web.service';
import { KakaoLoginExceptionCode, KakaoLoginRedirectState } from './kakao-login.type';

@Injectable({
  providedIn: 'root',
})
export class KakaoLoginService {
  readonly isPluginAvailable = Capacitor.isNativePlatform();

  constructor(
    private locationStrategy: LocationStrategy,
    private router: Router,
    private authService: AuthService,
    private kakaoLoginWebService: KakaoLoginWebService
  ) {}

  init(jsAppKey: string): void {
    if (!this.isPluginAvailable) {
      this.kakaoLoginWebService.init(jsAppKey);
    }
  }

  login(ignoreCancelError?: boolean): Observable<void> {
    if (this.isPluginAvailable) {
      return from(KakaoLogin.login()).pipe(
        concatMap((oAuthToken) =>
          this.authService.signInKakao({
            refreshToken: oAuthToken.refreshToken,
          })
        ),
        catchError((err) => {
          if (ignoreCancelError) {
            if (err instanceof CapacitorException) {
              const code = err.code as KakaoLoginExceptionCode | undefined;
              if (code === KakaoLoginExceptionCode.accessDenied) {
                return EMPTY;
              }
            }
          }
          return throwError(() => err);
        })
      );
    }

    const baseHref = this.locationStrategy.getBaseHref();
    const redirectUri = `${window.location.origin}${baseHref.replace(/\/$/, '')}/oauth/kakao`;
    const state: KakaoLoginRedirectState = {
      r: this.router.url,
      ice: ignoreCancelError ? true : undefined,
    };

    return this.kakaoLoginWebService.authorize({
      redirectUri,
      state: JSON.stringify(state),
    });
  }

  logout(): Observable<void> {
    if (this.isPluginAvailable) {
      return from(KakaoLogin.logout());
    }
    return this.kakaoLoginWebService.logout();
  }
}
