import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { HammerGestureConfig, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import 'hammerjs';
import { register } from 'swiper/element/bundle';

import { AlertModule } from '../alert';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ErrorModule } from './common/error/error.module';
import { GuardsModule } from './common/guards/guards.module';
import { ServicesModule } from './common/services/services.module';
import { HammerConfig } from './hammer-config';

register();

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    HammerModule,
    HttpClientModule,
    IonicModule.forRoot({
      backButtonIcon: 'ch-back',
      backButtonText: '',
      backButtonDefaultHref: '/',
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: 'en',
    }),
    AlertModule.forRoot(),
    ErrorModule,
    GuardsModule,
    ServicesModule,
    AppRoutingModule,
  ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, { provide: HammerGestureConfig, useClass: HammerConfig }, Storage],
  bootstrap: [AppComponent],
})
export class AppModule {}
