import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, catchError, map, of, skip, startWith, switchMap, tap } from 'rxjs';
import { Notice } from '../../../types/common';
import { completeAll } from '../../../utils/complete-all';
import { ApiService } from '../api/api.service';
import { BaseService } from '../base.service';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root',
})
export class NoticeService extends BaseService implements OnDestroy {
  readonly newNoticesAvailable$: Observable<boolean>;

  private readonly newNoticesAvailableSubject = new BehaviorSubject<boolean>(false);

  constructor(private apiService: ApiService, private userService: UserService) {
    super();

    this.newNoticesAvailable$ = this.newNoticesAvailableSubject.asObservable();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    completeAll([this.newNoticesAvailableSubject]);
  }

  /** 2번 이상 호출하지 않도록 주의 */
  async init(initNotices: Array<Notice> | null): Promise<void> {
    this.newNoticesAvailableSubject.next(initNotices?.some((item) => item.isNew) ?? false);

    this.subscription = this.userService.me$
      .pipe(
        skip(1),
        switchMap((userMe) =>
          userMe != null
            ? this.apiService.getNoticeList({}).pipe(
                map((res) => res.result.list),
                startWith([]),
                catchError((err) => {
                  console.error(err);
                  return of([]);
                })
              )
            : of([])
        )
      )
      .subscribe((list) => {
        this.newNoticesAvailableSubject.next(list.some((item) => item.isNew));
      });
  }

  detail(noticeId: string): Observable<Notice> {
    return this.apiService.getNoticeDetail({ noticeId }).pipe(map((res) => res.result.notice));
  }

  list(read?: boolean): Observable<Array<Notice>> {
    return this.apiService.getNoticeList({ read }).pipe(
      map((res) => res.result.list),
      tap(() => {
        if (read) {
          this.newNoticesAvailableSubject.next(false);
        }
      })
    );
  }
}
