import { CommonError, isCommonError } from '../../common-error';
import { APIErrorCode } from '../constants/api-error-code';
import { APIErrorData } from '../types/api-error-data';

export function isAPIErrorWithCode<CodeType extends APIErrorCode>(
  value: unknown,
  code: CodeType
): value is CommonError<CodeType, APIErrorData extends Record<CodeType, any> ? APIErrorData[CodeType] : unknown> {
  return isCommonError(value) && value.code === code;
}
