export interface KakaoLoginPlugin {
  login(): Promise<KakaoLoginOAuthToken>;
  logout(): Promise<void>;
  unlink(): Promise<void>;
  getToken(): Promise<KakaoLoginOAuthToken>;
}

export interface KakaoLoginOAuthToken {
  accessToken: string;
  /** timestamp */
  accessTokenExpiresAt: number;
  refreshToken: string;
  /** timestamp */
  refreshTokenExpiresAt: number;
  scopes?: Array<string>;
}
