import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Platform } from '@ionic/angular';
import { Observable, filter, fromEvent, map, merge, take, timer } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OpenInAppService {
  private readonly isAndroidApp: boolean;
  private readonly isIosApp: boolean;
  private readonly isAndroidBrowser: boolean;
  private readonly isIosBrowser: boolean;

  constructor(@Inject(PLATFORM_ID) platformId: object, platform: Platform) {
    const isBrowser = isPlatformBrowser(platformId);

    this.isAndroidApp = isBrowser && Capacitor.getPlatform() === 'android';
    this.isIosApp = isBrowser && Capacitor.getPlatform() === 'ios';
    this.isAndroidBrowser = isBrowser && !Capacitor.isNativePlatform() && platform.is('android');
    this.isIosBrowser = isBrowser && !Capacitor.isNativePlatform() && platform.is('ios');
  }

  /**
   * 앱에서 url을 엽니다. 성공 여부를 반환하지만 정확하지는 않습니다.
   * @param url 앱에서 열 url
   */
  openInApp(url: string, timeoutMs: number = 2000): Observable<boolean> {
    return new Observable((subscriber) => {
      if (!(this.isAndroidBrowser || this.isIosBrowser)) {
        subscriber.next(false);
        subscriber.complete();
        return;
      }

      const sub = merge(
        fromEvent(document, 'visibilitychange').pipe(
          filter(() => document.visibilityState === 'hidden'),
          map(() => true)
        ),
        timer(Math.max(timeoutMs, 0)).pipe(map(() => false))
      )
        .pipe(take(1))
        .subscribe(subscriber);

      const normalizedUrl = url.startsWith('/') ? url : '/' + url;

      const anchor = document.createElement('a');
      anchor.href = this.isAndroidBrowser
        ? `intent://${location.host}${normalizedUrl}#Intent;package=${environment.androidPackageName};scheme=https;end`
        : `celebhere://app${normalizedUrl}`;
      anchor.style.opacity = '0';
      anchor.style.pointerEvents = 'none';
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);

      return sub;
    });
  }

  getStoreUrl(platform?: 'android' | 'ios'): string | null {
    if (platform === 'android' || (platform == null && (this.isAndroidApp || this.isAndroidBrowser))) {
      return `https://play.google.com/store/apps/details?id=${environment.androidPackageName}`;
    } else if (platform === 'ios' || (platform == null && (this.isIosApp || this.isIosBrowser))) {
      return `https://apps.apple.com/kr/app/apple-store/id${environment.appStoreId}`;
    }
    return null;
  }

  openStore(platform?: 'android' | 'ios'): boolean {
    const url = this.getStoreUrl(platform);
    if (!url) {
      return false;
    }

    try {
      const newWin = window.open(url, '_blank');

      return newWin != null;
    } catch (err) {
      console.error(err);
    }
    return false;
  }
}
