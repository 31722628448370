import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { CountryInfo } from './country.type';

// https://countrycode.org/
// https://stefangabos.github.io/world_countries/
export const COUNTRY_LIST: Array<CountryInfo> = [
  { code: 'AD', nameKey: marker('COMMON.COUNTRY_NAME.AD'), enName: 'Andorra', callingCode: '376' },
  { code: 'AE', nameKey: marker('COMMON.COUNTRY_NAME.AE'), enName: 'United Arab Emirates', callingCode: '971' },
  { code: 'AF', nameKey: marker('COMMON.COUNTRY_NAME.AF'), enName: 'Afghanistan', callingCode: '93' },
  { code: 'AG', nameKey: marker('COMMON.COUNTRY_NAME.AG'), enName: 'Antigua and Barbuda', callingCode: '1' },
  { code: 'AI', nameKey: marker('COMMON.COUNTRY_NAME.AI'), enName: 'Anguilla', callingCode: '1' },
  { code: 'AL', nameKey: marker('COMMON.COUNTRY_NAME.AL'), enName: 'Albania', callingCode: '355' },
  { code: 'AM', nameKey: marker('COMMON.COUNTRY_NAME.AM'), enName: 'Armenia', callingCode: '374' },
  { code: 'AN', nameKey: marker('COMMON.COUNTRY_NAME.AN'), enName: 'Netherlands Antilles', callingCode: '599' },
  { code: 'AO', nameKey: marker('COMMON.COUNTRY_NAME.AO'), enName: 'Angola', callingCode: '244' },
  { code: 'AQ', nameKey: marker('COMMON.COUNTRY_NAME.AQ'), enName: 'Antarctica', callingCode: '672' },
  { code: 'AR', nameKey: marker('COMMON.COUNTRY_NAME.AR'), enName: 'Argentina', callingCode: '54' },
  { code: 'AS', nameKey: marker('COMMON.COUNTRY_NAME.AS'), enName: 'American Samoa', callingCode: '1' },
  { code: 'AT', nameKey: marker('COMMON.COUNTRY_NAME.AT'), enName: 'Austria', callingCode: '43' },
  { code: 'AU', nameKey: marker('COMMON.COUNTRY_NAME.AU'), enName: 'Australia', callingCode: '61' },
  { code: 'AW', nameKey: marker('COMMON.COUNTRY_NAME.AW'), enName: 'Aruba', callingCode: '297' },
  { code: 'AZ', nameKey: marker('COMMON.COUNTRY_NAME.AZ'), enName: 'Azerbaijan', callingCode: '994' },
  { code: 'BA', nameKey: marker('COMMON.COUNTRY_NAME.BA'), enName: 'Bosnia and Herzegovina', callingCode: '387' },
  { code: 'BB', nameKey: marker('COMMON.COUNTRY_NAME.BB'), enName: 'Barbados', callingCode: '1' },
  { code: 'BD', nameKey: marker('COMMON.COUNTRY_NAME.BD'), enName: 'Bangladesh', callingCode: '880' },
  { code: 'BE', nameKey: marker('COMMON.COUNTRY_NAME.BE'), enName: 'Belgium', callingCode: '32' },
  { code: 'BF', nameKey: marker('COMMON.COUNTRY_NAME.BF'), enName: 'Burkina Faso', callingCode: '226' },
  { code: 'BG', nameKey: marker('COMMON.COUNTRY_NAME.BG'), enName: 'Bulgaria', callingCode: '359' },
  { code: 'BH', nameKey: marker('COMMON.COUNTRY_NAME.BH'), enName: 'Bahrain', callingCode: '973' },
  { code: 'BI', nameKey: marker('COMMON.COUNTRY_NAME.BI'), enName: 'Burundi', callingCode: '257' },
  { code: 'BJ', nameKey: marker('COMMON.COUNTRY_NAME.BJ'), enName: 'Benin', callingCode: '229' },
  { code: 'BL', nameKey: marker('COMMON.COUNTRY_NAME.BL'), enName: 'Saint Barthelemy', callingCode: '590' },
  { code: 'BM', nameKey: marker('COMMON.COUNTRY_NAME.BM'), enName: 'Bermuda', callingCode: '1' },
  { code: 'BN', nameKey: marker('COMMON.COUNTRY_NAME.BN'), enName: 'Brunei', callingCode: '673' },
  { code: 'BO', nameKey: marker('COMMON.COUNTRY_NAME.BO'), enName: 'Bolivia', callingCode: '591' },
  { code: 'BR', nameKey: marker('COMMON.COUNTRY_NAME.BR'), enName: 'Brazil', callingCode: '55' },
  { code: 'BS', nameKey: marker('COMMON.COUNTRY_NAME.BS'), enName: 'Bahamas', callingCode: '1' },
  { code: 'BT', nameKey: marker('COMMON.COUNTRY_NAME.BT'), enName: 'Bhutan', callingCode: '975' },
  { code: 'BW', nameKey: marker('COMMON.COUNTRY_NAME.BW'), enName: 'Botswana', callingCode: '267' },
  { code: 'BY', nameKey: marker('COMMON.COUNTRY_NAME.BY'), enName: 'Belarus', callingCode: '375' },
  { code: 'BZ', nameKey: marker('COMMON.COUNTRY_NAME.BZ'), enName: 'Belize', callingCode: '501' },
  { code: 'CA', nameKey: marker('COMMON.COUNTRY_NAME.CA'), enName: 'Canada', callingCode: '1' },
  { code: 'CC', nameKey: marker('COMMON.COUNTRY_NAME.CC'), enName: 'Cocos Islands', callingCode: '61' },
  { code: 'CD', nameKey: marker('COMMON.COUNTRY_NAME.CD'), enName: 'Democratic Republic of the Congo', callingCode: '243' },
  { code: 'CF', nameKey: marker('COMMON.COUNTRY_NAME.CF'), enName: 'Central African Republic', callingCode: '236' },
  { code: 'CG', nameKey: marker('COMMON.COUNTRY_NAME.CG'), enName: 'Republic of the Congo', callingCode: '242' },
  { code: 'CH', nameKey: marker('COMMON.COUNTRY_NAME.CH'), enName: 'Switzerland', callingCode: '41' },
  { code: 'CI', nameKey: marker('COMMON.COUNTRY_NAME.CI'), enName: 'Ivory Coast', callingCode: '225' },
  { code: 'CK', nameKey: marker('COMMON.COUNTRY_NAME.CK'), enName: 'Cook Islands', callingCode: '682' },
  { code: 'CL', nameKey: marker('COMMON.COUNTRY_NAME.CL'), enName: 'Chile', callingCode: '56' },
  { code: 'CM', nameKey: marker('COMMON.COUNTRY_NAME.CM'), enName: 'Cameroon', callingCode: '237' },
  { code: 'CN', nameKey: marker('COMMON.COUNTRY_NAME.CN'), enName: 'China', callingCode: '86' },
  { code: 'CO', nameKey: marker('COMMON.COUNTRY_NAME.CO'), enName: 'Colombia', callingCode: '57' },
  { code: 'CR', nameKey: marker('COMMON.COUNTRY_NAME.CR'), enName: 'Costa Rica', callingCode: '506' },
  { code: 'CU', nameKey: marker('COMMON.COUNTRY_NAME.CU'), enName: 'Cuba', callingCode: '53' },
  { code: 'CV', nameKey: marker('COMMON.COUNTRY_NAME.CV'), enName: 'Cape Verde', callingCode: '238' },
  { code: 'CW', nameKey: marker('COMMON.COUNTRY_NAME.CW'), enName: 'Curacao', callingCode: '599' },
  { code: 'CX', nameKey: marker('COMMON.COUNTRY_NAME.CX'), enName: 'Christmas Island', callingCode: '61' },
  { code: 'CY', nameKey: marker('COMMON.COUNTRY_NAME.CY'), enName: 'Cyprus', callingCode: '357' },
  { code: 'CZ', nameKey: marker('COMMON.COUNTRY_NAME.CZ'), enName: 'Czech Republic', callingCode: '420' },
  { code: 'DE', nameKey: marker('COMMON.COUNTRY_NAME.DE'), enName: 'Germany', callingCode: '49' },
  { code: 'DJ', nameKey: marker('COMMON.COUNTRY_NAME.DJ'), enName: 'Djibouti', callingCode: '253' },
  { code: 'DK', nameKey: marker('COMMON.COUNTRY_NAME.DK'), enName: 'Denmark', callingCode: '45' },
  { code: 'DM', nameKey: marker('COMMON.COUNTRY_NAME.DM'), enName: 'Dominica', callingCode: '1' },
  { code: 'DO', nameKey: marker('COMMON.COUNTRY_NAME.DO'), enName: 'Dominican Republic', callingCode: '1' },
  { code: 'DZ', nameKey: marker('COMMON.COUNTRY_NAME.DZ'), enName: 'Algeria', callingCode: '213' },
  { code: 'EC', nameKey: marker('COMMON.COUNTRY_NAME.EC'), enName: 'Ecuador', callingCode: '593' },
  { code: 'EE', nameKey: marker('COMMON.COUNTRY_NAME.EE'), enName: 'Estonia', callingCode: '372' },
  { code: 'EG', nameKey: marker('COMMON.COUNTRY_NAME.EG'), enName: 'Egypt', callingCode: '20' },
  { code: 'EH', nameKey: marker('COMMON.COUNTRY_NAME.EH'), enName: 'Western Sahara', callingCode: '212' },
  { code: 'ER', nameKey: marker('COMMON.COUNTRY_NAME.ER'), enName: 'Eritrea', callingCode: '291' },
  { code: 'ES', nameKey: marker('COMMON.COUNTRY_NAME.ES'), enName: 'Spain', callingCode: '34' },
  { code: 'ET', nameKey: marker('COMMON.COUNTRY_NAME.ET'), enName: 'Ethiopia', callingCode: '251' },
  { code: 'FI', nameKey: marker('COMMON.COUNTRY_NAME.FI'), enName: 'Finland', callingCode: '358' },
  { code: 'FJ', nameKey: marker('COMMON.COUNTRY_NAME.FJ'), enName: 'Fiji', callingCode: '679' },
  { code: 'FK', nameKey: marker('COMMON.COUNTRY_NAME.FK'), enName: 'Falkland Islands', callingCode: '500' },
  { code: 'FM', nameKey: marker('COMMON.COUNTRY_NAME.FM'), enName: 'Micronesia', callingCode: '691' },
  { code: 'FO', nameKey: marker('COMMON.COUNTRY_NAME.FO'), enName: 'Faroe Islands', callingCode: '298' },
  { code: 'FR', nameKey: marker('COMMON.COUNTRY_NAME.FR'), enName: 'France', callingCode: '33' },
  { code: 'GA', nameKey: marker('COMMON.COUNTRY_NAME.GA'), enName: 'Gabon', callingCode: '241' },
  { code: 'GB', nameKey: marker('COMMON.COUNTRY_NAME.GB'), enName: 'United Kingdom', callingCode: '44' },
  { code: 'GD', nameKey: marker('COMMON.COUNTRY_NAME.GD'), enName: 'Grenada', callingCode: '1' },
  { code: 'GE', nameKey: marker('COMMON.COUNTRY_NAME.GE'), enName: 'Georgia', callingCode: '995' },
  { code: 'GG', nameKey: marker('COMMON.COUNTRY_NAME.GG'), enName: 'Guernsey', callingCode: '44' },
  { code: 'GH', nameKey: marker('COMMON.COUNTRY_NAME.GH'), enName: 'Ghana', callingCode: '233' },
  { code: 'GI', nameKey: marker('COMMON.COUNTRY_NAME.GI'), enName: 'Gibraltar', callingCode: '350' },
  { code: 'GL', nameKey: marker('COMMON.COUNTRY_NAME.GL'), enName: 'Greenland', callingCode: '299' },
  { code: 'GM', nameKey: marker('COMMON.COUNTRY_NAME.GM'), enName: 'Gambia', callingCode: '220' },
  { code: 'GN', nameKey: marker('COMMON.COUNTRY_NAME.GN'), enName: 'Guinea', callingCode: '224' },
  { code: 'GQ', nameKey: marker('COMMON.COUNTRY_NAME.GQ'), enName: 'Equatorial Guinea', callingCode: '240' },
  { code: 'GR', nameKey: marker('COMMON.COUNTRY_NAME.GR'), enName: 'Greece', callingCode: '30' },
  { code: 'GT', nameKey: marker('COMMON.COUNTRY_NAME.GT'), enName: 'Guatemala', callingCode: '502' },
  { code: 'GU', nameKey: marker('COMMON.COUNTRY_NAME.GU'), enName: 'Guam', callingCode: '1' },
  { code: 'GW', nameKey: marker('COMMON.COUNTRY_NAME.GW'), enName: 'Guinea-Bissau', callingCode: '245' },
  { code: 'GY', nameKey: marker('COMMON.COUNTRY_NAME.GY'), enName: 'Guyana', callingCode: '592' },
  { code: 'HK', nameKey: marker('COMMON.COUNTRY_NAME.HK'), enName: 'Hong Kong', callingCode: '852' },
  { code: 'HN', nameKey: marker('COMMON.COUNTRY_NAME.HN'), enName: 'Honduras', callingCode: '504' },
  { code: 'HR', nameKey: marker('COMMON.COUNTRY_NAME.HR'), enName: 'Croatia', callingCode: '385' },
  { code: 'HT', nameKey: marker('COMMON.COUNTRY_NAME.HT'), enName: 'Haiti', callingCode: '509' },
  { code: 'HU', nameKey: marker('COMMON.COUNTRY_NAME.HU'), enName: 'Hungary', callingCode: '36' },
  { code: 'ID', nameKey: marker('COMMON.COUNTRY_NAME.ID'), enName: 'Indonesia', callingCode: '62' },
  { code: 'IE', nameKey: marker('COMMON.COUNTRY_NAME.IE'), enName: 'Ireland', callingCode: '353' },
  { code: 'IL', nameKey: marker('COMMON.COUNTRY_NAME.IL'), enName: 'Israel', callingCode: '972' },
  { code: 'IM', nameKey: marker('COMMON.COUNTRY_NAME.IM'), enName: 'Isle of Man', callingCode: '44' },
  { code: 'IN', nameKey: marker('COMMON.COUNTRY_NAME.IN'), enName: 'India', callingCode: '91' },
  { code: 'IO', nameKey: marker('COMMON.COUNTRY_NAME.IO'), enName: 'British Indian Ocean Territory', callingCode: '246' },
  { code: 'IQ', nameKey: marker('COMMON.COUNTRY_NAME.IQ'), enName: 'Iraq', callingCode: '964' },
  { code: 'IR', nameKey: marker('COMMON.COUNTRY_NAME.IR'), enName: 'Iran', callingCode: '98' },
  { code: 'IS', nameKey: marker('COMMON.COUNTRY_NAME.IS'), enName: 'Iceland', callingCode: '354' },
  { code: 'IT', nameKey: marker('COMMON.COUNTRY_NAME.IT'), enName: 'Italy', callingCode: '39' },
  { code: 'JE', nameKey: marker('COMMON.COUNTRY_NAME.JE'), enName: 'Jersey', callingCode: '44' },
  { code: 'JM', nameKey: marker('COMMON.COUNTRY_NAME.JM'), enName: 'Jamaica', callingCode: '1' },
  { code: 'JO', nameKey: marker('COMMON.COUNTRY_NAME.JO'), enName: 'Jordan', callingCode: '962' },
  { code: 'JP', nameKey: marker('COMMON.COUNTRY_NAME.JP'), enName: 'Japan', callingCode: '81' },
  { code: 'KE', nameKey: marker('COMMON.COUNTRY_NAME.KE'), enName: 'Kenya', callingCode: '254' },
  { code: 'KG', nameKey: marker('COMMON.COUNTRY_NAME.KG'), enName: 'Kyrgyzstan', callingCode: '996' },
  { code: 'KH', nameKey: marker('COMMON.COUNTRY_NAME.KH'), enName: 'Cambodia', callingCode: '855' },
  { code: 'KI', nameKey: marker('COMMON.COUNTRY_NAME.KI'), enName: 'Kiribati', callingCode: '686' },
  { code: 'KM', nameKey: marker('COMMON.COUNTRY_NAME.KM'), enName: 'Comoros', callingCode: '269' },
  { code: 'KN', nameKey: marker('COMMON.COUNTRY_NAME.KN'), enName: 'Saint Kitts and Nevis', callingCode: '1' },
  { code: 'KP', nameKey: marker('COMMON.COUNTRY_NAME.KP'), enName: 'North Korea', callingCode: '850' },
  { code: 'KR', nameKey: marker('COMMON.COUNTRY_NAME.KR'), enName: 'South Korea', callingCode: '82' },
  { code: 'KW', nameKey: marker('COMMON.COUNTRY_NAME.KW'), enName: 'Kuwait', callingCode: '965' },
  { code: 'KY', nameKey: marker('COMMON.COUNTRY_NAME.KY'), enName: 'Cayman Islands', callingCode: '1' },
  { code: 'KZ', nameKey: marker('COMMON.COUNTRY_NAME.KZ'), enName: 'Kazakhstan', callingCode: '7' },
  { code: 'LA', nameKey: marker('COMMON.COUNTRY_NAME.LA'), enName: 'Laos', callingCode: '856' },
  { code: 'LB', nameKey: marker('COMMON.COUNTRY_NAME.LB'), enName: 'Lebanon', callingCode: '961' },
  { code: 'LC', nameKey: marker('COMMON.COUNTRY_NAME.LC'), enName: 'Saint Lucia', callingCode: '1' },
  { code: 'LI', nameKey: marker('COMMON.COUNTRY_NAME.LI'), enName: 'Liechtenstein', callingCode: '423' },
  { code: 'LK', nameKey: marker('COMMON.COUNTRY_NAME.LK'), enName: 'Sri Lanka', callingCode: '94' },
  { code: 'LR', nameKey: marker('COMMON.COUNTRY_NAME.LR'), enName: 'Liberia', callingCode: '231' },
  { code: 'LS', nameKey: marker('COMMON.COUNTRY_NAME.LS'), enName: 'Lesotho', callingCode: '266' },
  { code: 'LT', nameKey: marker('COMMON.COUNTRY_NAME.LT'), enName: 'Lithuania', callingCode: '370' },
  { code: 'LU', nameKey: marker('COMMON.COUNTRY_NAME.LU'), enName: 'Luxembourg', callingCode: '352' },
  { code: 'LV', nameKey: marker('COMMON.COUNTRY_NAME.LV'), enName: 'Latvia', callingCode: '371' },
  { code: 'LY', nameKey: marker('COMMON.COUNTRY_NAME.LY'), enName: 'Libya', callingCode: '218' },
  { code: 'MA', nameKey: marker('COMMON.COUNTRY_NAME.MA'), enName: 'Morocco', callingCode: '212' },
  { code: 'MC', nameKey: marker('COMMON.COUNTRY_NAME.MC'), enName: 'Monaco', callingCode: '377' },
  { code: 'MD', nameKey: marker('COMMON.COUNTRY_NAME.MD'), enName: 'Moldova', callingCode: '373' },
  { code: 'ME', nameKey: marker('COMMON.COUNTRY_NAME.ME'), enName: 'Montenegro', callingCode: '382' },
  { code: 'MF', nameKey: marker('COMMON.COUNTRY_NAME.MF'), enName: 'Saint Martin', callingCode: '590' },
  { code: 'MG', nameKey: marker('COMMON.COUNTRY_NAME.MG'), enName: 'Madagascar', callingCode: '261' },
  { code: 'MH', nameKey: marker('COMMON.COUNTRY_NAME.MH'), enName: 'Marshall Islands', callingCode: '692' },
  { code: 'MK', nameKey: marker('COMMON.COUNTRY_NAME.MK'), enName: 'Macedonia', callingCode: '389' },
  { code: 'ML', nameKey: marker('COMMON.COUNTRY_NAME.ML'), enName: 'Mali', callingCode: '223' },
  { code: 'MM', nameKey: marker('COMMON.COUNTRY_NAME.MM'), enName: 'Myanmar', callingCode: '95' },
  { code: 'MN', nameKey: marker('COMMON.COUNTRY_NAME.MN'), enName: 'Mongolia', callingCode: '976' },
  { code: 'MO', nameKey: marker('COMMON.COUNTRY_NAME.MO'), enName: 'Macau', callingCode: '853' },
  { code: 'MP', nameKey: marker('COMMON.COUNTRY_NAME.MP'), enName: 'Northern Mariana Islands', callingCode: '1' },
  { code: 'MR', nameKey: marker('COMMON.COUNTRY_NAME.MR'), enName: 'Mauritania', callingCode: '222' },
  { code: 'MS', nameKey: marker('COMMON.COUNTRY_NAME.MS'), enName: 'Montserrat', callingCode: '1' },
  { code: 'MT', nameKey: marker('COMMON.COUNTRY_NAME.MT'), enName: 'Malta', callingCode: '356' },
  { code: 'MU', nameKey: marker('COMMON.COUNTRY_NAME.MU'), enName: 'Mauritius', callingCode: '230' },
  { code: 'MV', nameKey: marker('COMMON.COUNTRY_NAME.MV'), enName: 'Maldives', callingCode: '960' },
  { code: 'MW', nameKey: marker('COMMON.COUNTRY_NAME.MW'), enName: 'Malawi', callingCode: '265' },
  { code: 'MX', nameKey: marker('COMMON.COUNTRY_NAME.MX'), enName: 'Mexico', callingCode: '52' },
  { code: 'MY', nameKey: marker('COMMON.COUNTRY_NAME.MY'), enName: 'Malaysia', callingCode: '60' },
  { code: 'MZ', nameKey: marker('COMMON.COUNTRY_NAME.MZ'), enName: 'Mozambique', callingCode: '258' },
  { code: 'NA', nameKey: marker('COMMON.COUNTRY_NAME.NA'), enName: 'Namibia', callingCode: '264' },
  { code: 'NC', nameKey: marker('COMMON.COUNTRY_NAME.NC'), enName: 'New Caledonia', callingCode: '687' },
  { code: 'NE', nameKey: marker('COMMON.COUNTRY_NAME.NE'), enName: 'Niger', callingCode: '227' },
  { code: 'NG', nameKey: marker('COMMON.COUNTRY_NAME.NG'), enName: 'Nigeria', callingCode: '234' },
  { code: 'NI', nameKey: marker('COMMON.COUNTRY_NAME.NI'), enName: 'Nicaragua', callingCode: '505' },
  { code: 'NL', nameKey: marker('COMMON.COUNTRY_NAME.NL'), enName: 'Netherlands', callingCode: '31' },
  { code: 'NO', nameKey: marker('COMMON.COUNTRY_NAME.NO'), enName: 'Norway', callingCode: '47' },
  { code: 'NP', nameKey: marker('COMMON.COUNTRY_NAME.NP'), enName: 'Nepal', callingCode: '977' },
  { code: 'NR', nameKey: marker('COMMON.COUNTRY_NAME.NR'), enName: 'Nauru', callingCode: '674' },
  { code: 'NU', nameKey: marker('COMMON.COUNTRY_NAME.NU'), enName: 'Niue', callingCode: '683' },
  { code: 'NZ', nameKey: marker('COMMON.COUNTRY_NAME.NZ'), enName: 'New Zealand', callingCode: '64' },
  { code: 'OM', nameKey: marker('COMMON.COUNTRY_NAME.OM'), enName: 'Oman', callingCode: '968' },
  { code: 'PA', nameKey: marker('COMMON.COUNTRY_NAME.PA'), enName: 'Panama', callingCode: '507' },
  { code: 'PE', nameKey: marker('COMMON.COUNTRY_NAME.PE'), enName: 'Peru', callingCode: '51' },
  { code: 'PF', nameKey: marker('COMMON.COUNTRY_NAME.PF'), enName: 'French Polynesia', callingCode: '689' },
  { code: 'PG', nameKey: marker('COMMON.COUNTRY_NAME.PG'), enName: 'Papua New Guinea', callingCode: '675' },
  { code: 'PH', nameKey: marker('COMMON.COUNTRY_NAME.PH'), enName: 'Philippines', callingCode: '63' },
  { code: 'PK', nameKey: marker('COMMON.COUNTRY_NAME.PK'), enName: 'Pakistan', callingCode: '92' },
  { code: 'PL', nameKey: marker('COMMON.COUNTRY_NAME.PL'), enName: 'Poland', callingCode: '48' },
  { code: 'PM', nameKey: marker('COMMON.COUNTRY_NAME.PM'), enName: 'Saint Pierre and Miquelon', callingCode: '508' },
  { code: 'PN', nameKey: marker('COMMON.COUNTRY_NAME.PN'), enName: 'Pitcairn', callingCode: '64' },
  { code: 'PR', nameKey: marker('COMMON.COUNTRY_NAME.PR'), enName: 'Puerto Rico', callingCode: '1' },
  { code: 'PS', nameKey: marker('COMMON.COUNTRY_NAME.PS'), enName: 'Palestine', callingCode: '970' },
  { code: 'PT', nameKey: marker('COMMON.COUNTRY_NAME.PT'), enName: 'Portugal', callingCode: '351' },
  { code: 'PW', nameKey: marker('COMMON.COUNTRY_NAME.PW'), enName: 'Palau', callingCode: '680' },
  { code: 'PY', nameKey: marker('COMMON.COUNTRY_NAME.PY'), enName: 'Paraguay', callingCode: '595' },
  { code: 'QA', nameKey: marker('COMMON.COUNTRY_NAME.QA'), enName: 'Qatar', callingCode: '974' },
  { code: 'RE', nameKey: marker('COMMON.COUNTRY_NAME.RE'), enName: 'Reunion', callingCode: '262' },
  { code: 'RO', nameKey: marker('COMMON.COUNTRY_NAME.RO'), enName: 'Romania', callingCode: '40' },
  { code: 'RS', nameKey: marker('COMMON.COUNTRY_NAME.RS'), enName: 'Serbia', callingCode: '381' },
  { code: 'RU', nameKey: marker('COMMON.COUNTRY_NAME.RU'), enName: 'Russia', callingCode: '7' },
  { code: 'RW', nameKey: marker('COMMON.COUNTRY_NAME.RW'), enName: 'Rwanda', callingCode: '250' },
  { code: 'SA', nameKey: marker('COMMON.COUNTRY_NAME.SA'), enName: 'Saudi Arabia', callingCode: '966' },
  { code: 'SB', nameKey: marker('COMMON.COUNTRY_NAME.SB'), enName: 'Solomon Islands', callingCode: '677' },
  { code: 'SC', nameKey: marker('COMMON.COUNTRY_NAME.SC'), enName: 'Seychelles', callingCode: '248' },
  { code: 'SD', nameKey: marker('COMMON.COUNTRY_NAME.SD'), enName: 'Sudan', callingCode: '249' },
  { code: 'SE', nameKey: marker('COMMON.COUNTRY_NAME.SE'), enName: 'Sweden', callingCode: '46' },
  { code: 'SG', nameKey: marker('COMMON.COUNTRY_NAME.SG'), enName: 'Singapore', callingCode: '65' },
  { code: 'SH', nameKey: marker('COMMON.COUNTRY_NAME.SH'), enName: 'Saint Helena', callingCode: '290' },
  { code: 'SI', nameKey: marker('COMMON.COUNTRY_NAME.SI'), enName: 'Slovenia', callingCode: '386' },
  { code: 'SJ', nameKey: marker('COMMON.COUNTRY_NAME.SJ'), enName: 'Svalbard and Jan Mayen', callingCode: '47' },
  { code: 'SK', nameKey: marker('COMMON.COUNTRY_NAME.SK'), enName: 'Slovakia', callingCode: '421' },
  { code: 'SL', nameKey: marker('COMMON.COUNTRY_NAME.SL'), enName: 'Sierra Leone', callingCode: '232' },
  { code: 'SM', nameKey: marker('COMMON.COUNTRY_NAME.SM'), enName: 'San Marino', callingCode: '378' },
  { code: 'SN', nameKey: marker('COMMON.COUNTRY_NAME.SN'), enName: 'Senegal', callingCode: '221' },
  { code: 'SO', nameKey: marker('COMMON.COUNTRY_NAME.SO'), enName: 'Somalia', callingCode: '252' },
  { code: 'SR', nameKey: marker('COMMON.COUNTRY_NAME.SR'), enName: 'Suriname', callingCode: '597' },
  { code: 'SS', nameKey: marker('COMMON.COUNTRY_NAME.SS'), enName: 'South Sudan', callingCode: '211' },
  { code: 'ST', nameKey: marker('COMMON.COUNTRY_NAME.ST'), enName: 'Sao Tome and Principe', callingCode: '239' },
  { code: 'SV', nameKey: marker('COMMON.COUNTRY_NAME.SV'), enName: 'El Salvador', callingCode: '503' },
  { code: 'SX', nameKey: marker('COMMON.COUNTRY_NAME.SX'), enName: 'Sint Maarten', callingCode: '1' },
  { code: 'SY', nameKey: marker('COMMON.COUNTRY_NAME.SY'), enName: 'Syria', callingCode: '963' },
  { code: 'SZ', nameKey: marker('COMMON.COUNTRY_NAME.SZ'), enName: 'Swaziland', callingCode: '268' },
  { code: 'TC', nameKey: marker('COMMON.COUNTRY_NAME.TC'), enName: 'Turks and Caicos Islands', callingCode: '1' },
  { code: 'TD', nameKey: marker('COMMON.COUNTRY_NAME.TD'), enName: 'Chad', callingCode: '235' },
  { code: 'TG', nameKey: marker('COMMON.COUNTRY_NAME.TG'), enName: 'Togo', callingCode: '228' },
  { code: 'TH', nameKey: marker('COMMON.COUNTRY_NAME.TH'), enName: 'Thailand', callingCode: '66' },
  { code: 'TJ', nameKey: marker('COMMON.COUNTRY_NAME.TJ'), enName: 'Tajikistan', callingCode: '992' },
  { code: 'TK', nameKey: marker('COMMON.COUNTRY_NAME.TK'), enName: 'Tokelau', callingCode: '690' },
  { code: 'TL', nameKey: marker('COMMON.COUNTRY_NAME.TL'), enName: 'East Timor', callingCode: '670' },
  { code: 'TM', nameKey: marker('COMMON.COUNTRY_NAME.TM'), enName: 'Turkmenistan', callingCode: '993' },
  { code: 'TN', nameKey: marker('COMMON.COUNTRY_NAME.TN'), enName: 'Tunisia', callingCode: '216' },
  { code: 'TO', nameKey: marker('COMMON.COUNTRY_NAME.TO'), enName: 'Tonga', callingCode: '676' },
  { code: 'TR', nameKey: marker('COMMON.COUNTRY_NAME.TR'), enName: 'Turkey', callingCode: '90' },
  { code: 'TT', nameKey: marker('COMMON.COUNTRY_NAME.TT'), enName: 'Trinidad and Tobago', callingCode: '1' },
  { code: 'TV', nameKey: marker('COMMON.COUNTRY_NAME.TV'), enName: 'Tuvalu', callingCode: '688' },
  { code: 'TW', nameKey: marker('COMMON.COUNTRY_NAME.TW'), enName: 'Taiwan', callingCode: '886' },
  { code: 'TZ', nameKey: marker('COMMON.COUNTRY_NAME.TZ'), enName: 'Tanzania', callingCode: '255' },
  { code: 'UA', nameKey: marker('COMMON.COUNTRY_NAME.UA'), enName: 'Ukraine', callingCode: '380' },
  { code: 'UG', nameKey: marker('COMMON.COUNTRY_NAME.UG'), enName: 'Uganda', callingCode: '256' },
  { code: 'US', nameKey: marker('COMMON.COUNTRY_NAME.US'), enName: 'United States', callingCode: '1' },
  { code: 'UY', nameKey: marker('COMMON.COUNTRY_NAME.UY'), enName: 'Uruguay', callingCode: '598' },
  { code: 'UZ', nameKey: marker('COMMON.COUNTRY_NAME.UZ'), enName: 'Uzbekistan', callingCode: '998' },
  { code: 'VA', nameKey: marker('COMMON.COUNTRY_NAME.VA'), enName: 'Vatican', callingCode: '379' },
  { code: 'VC', nameKey: marker('COMMON.COUNTRY_NAME.VC'), enName: 'Saint Vincent and the Grenadines', callingCode: '1' },
  { code: 'VE', nameKey: marker('COMMON.COUNTRY_NAME.VE'), enName: 'Venezuela', callingCode: '58' },
  { code: 'VG', nameKey: marker('COMMON.COUNTRY_NAME.VG'), enName: 'British Virgin Islands', callingCode: '1' },
  { code: 'VI', nameKey: marker('COMMON.COUNTRY_NAME.VI'), enName: 'U.S. Virgin Islands', callingCode: '1' },
  { code: 'VN', nameKey: marker('COMMON.COUNTRY_NAME.VN'), enName: 'Vietnam', callingCode: '84' },
  { code: 'VU', nameKey: marker('COMMON.COUNTRY_NAME.VU'), enName: 'Vanuatu', callingCode: '678' },
  { code: 'WF', nameKey: marker('COMMON.COUNTRY_NAME.WF'), enName: 'Wallis and Futuna', callingCode: '681' },
  { code: 'WS', nameKey: marker('COMMON.COUNTRY_NAME.WS'), enName: 'Samoa', callingCode: '685' },
  { code: 'XK', nameKey: marker('COMMON.COUNTRY_NAME.XK'), enName: 'Kosovo', callingCode: '383' },
  { code: 'YE', nameKey: marker('COMMON.COUNTRY_NAME.YE'), enName: 'Yemen', callingCode: '967' },
  { code: 'YT', nameKey: marker('COMMON.COUNTRY_NAME.YT'), enName: 'Mayotte', callingCode: '262' },
  { code: 'ZA', nameKey: marker('COMMON.COUNTRY_NAME.ZA'), enName: 'South Africa', callingCode: '27' },
  { code: 'ZM', nameKey: marker('COMMON.COUNTRY_NAME.ZM'), enName: 'Zambia', callingCode: '260' },
  { code: 'ZW', nameKey: marker('COMMON.COUNTRY_NAME.ZW'), enName: 'Zimbabwe', callingCode: '263' },
];
