import { Injectable, OnDestroy } from '@angular/core';
import { Storage } from '@ionic/storage';
import { DateTime } from 'luxon';
import { BehaviorSubject, EMPTY, NEVER, Observable, Subject, combineLatest, merge, of, race, throwError } from 'rxjs';
import { bufferTime, catchError, concatMap, filter, map, share, shareReplay, skip, startWith, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { CommonError, isCommonErrorJson } from '../../../../common-error';
import {
  GetChatListFavoritedMessageQueryParams,
  GetChatListFavoritedMessageResult,
  GetChatListMessageQueryParams,
  GetChatListMessageResult,
  PostChatFavoriteMessageResult,
  PostChatUnfavoriteMessageResult,
} from '../../../types/api/chat';
import {
  Chat,
  ChatMessage,
  ChatMessageFavoriteUpdate,
  ChatMessageParams,
  ChatMessageReactionUpdate,
  ChatMessageReadUpdate,
} from '../../../types/common';
import { ChatMessageSentSuccess } from '../../../types/socket/chat';
import { isNotNullish } from '../../../utils';
import { hasAuthor } from '../../../utils/chat/has-author';
import { completeAll } from '../../../utils/complete-all';
import { ApiService } from '../api/api.service';
import { AuthService } from '../auth/auth.service';
import { BaseService } from '../base.service';
import { PermissionService } from '../permission/permission.service';
import { SocketService } from '../socket/socket.service';
import { SubscriptionService } from '../subscription/subscription.service';
import { UserService } from '../user/user.service';
import { ChatPrivate } from './chat.type';

enum StorageKey {
  chatList = 'celebhere.chat.chatList',
  messageList = 'celebhere.chat.messageList',
}

@Injectable({
  providedIn: 'root',
})
export class ChatService extends BaseService implements OnDestroy {
  private idCounter = 0;

  private readonly chatListSubject = new BehaviorSubject<Array<Chat>>([]);
  private readonly refreshChatListSubject = new Subject<void>();
  private readonly refreshChatListErrorSubject = new Subject<any>();
  private readonly chatUpdateSubject = new Subject<Chat>();
  /**
   * 메시지 읽음 처리용 Subject
   *
   * next 해놓으면 여러개 모아서 읽음 처리
   */
  private readonly readMessageSubject = new Subject<string>();

  /** 대화방 목록 */
  readonly chatList$: Observable<Array<Chat> | null>;
  /** 대화방 목록 (마지막 메시지 생성일 내림차순 정렬) */
  readonly chatListLastMessageCreatedAtDesc$: Observable<Array<Chat>>;
  /** 프라이빗 대화방 목록 */
  readonly chatListDM$: Observable<Array<ChatPrivate>>;
  /** 구독 대화방 목록 */
  readonly chatListSubscription$: Observable<Array<Chat>>;
  /** 라이브 대화방 목록 */
  readonly chatListCelebGroup$: Observable<Array<Chat>>;
  /** 새 메시지 이벤트 (수신, 발신) */
  readonly newMessage$: Observable<ChatMessage & { sendId?: string }>;
  /** 메시지 업데이트 이벤트 */
  readonly messageUpdate$: Observable<ChatMessage>;
  /** 메시지 삭제 이벤트 */
  readonly messageDelete$: Observable<{
    chatId: string;
    chatMessageId: string;
  }>;
  /** 메시지 즐겨찾기 업데이트 이벤트 */
  readonly messageFavoriteUpdate$: Observable<ChatMessageFavoriteUpdate>;
  /** 메시지 읽음 업데이트 이벤트 */
  readonly messageReadUpdate$: Observable<ChatMessageReadUpdate>;
  /** 메시지 반응 업데이트 이벤트 */
  readonly messageReactionUpdate$: Observable<ChatMessageReactionUpdate>;

  constructor(
    private storage: Storage,
    private authService: AuthService,
    private apiService: ApiService,
    permissionService: PermissionService,
    private socketService: SocketService,
    subscriptionService: SubscriptionService,
    private userService: UserService
  ) {
    super();

    this.chatList$ = this.chatListSubject.asObservable();

    this.chatListLastMessageCreatedAtDesc$ = this.chatListSubject.pipe(
      map((chatList) => {
        return (chatList ?? [])
          .slice()
          .sort(
            (a, b) =>
              (b.isBroadcast ? 1 : 0) - (a.isBroadcast ? 1 : 0) ||
              (b.lastMessage?.createdAt ?? b.createdAt).getTime() - (a.lastMessage?.createdAt ?? a.createdAt).getTime()
          );
      }),
      shareReplay(1)
    );

    // 조건 만족하지 않은 경우 프라이빗 대화방이 생기지 않으나, 있는 것처럼 보이도록 가짜 대화방 추가
    this.chatListDM$ = combineLatest([
      this.chatListSubject,
      subscriptionService.subscriptionList$,
      subscriptionService.subscriptionPremiumList$,
      permissionService.dmEnter$,
      permissionService.dmSend$,
    ]).pipe(
      map(([chatList, subscriptionList, subscriptionPremiumList, dmEnter, dmSend]) => {
        const existingCelebUserIds = (chatList ?? []).map((chat) => (chat.recipient?.isCeleb ? chat.recipient.id : undefined)).filter(isNotNullish);
        const fakeChats = subscriptionList
          .filter((subscription) => !existingCelebUserIds.includes(subscription.celebUserId))
          .map(
            (subscription): ChatPrivate => ({
              id: subscription.celebUserId,
              type: 'private',
              userIds: [subscription.userId, subscription.celebUserId],
              isDM: true,
              createdAt: subscription.createdAt,
              hasPremium: subscriptionPremiumList.some((subscriptionPremium) => subscriptionPremium.celebUserId === subscription.celebUserId),
              fake: true,
            })
          );

        return (chatList ?? [])
          .filter((chat) => (['private', 'private-broadcast'] as Array<Chat['type']>).includes(chat.type) || chat.isDM || chat.isBroadcast)
          .map(
            (chat): ChatPrivate =>
              chat.recipient != null
                ? {
                    ...chat,
                    canEnter: dmEnter.some((permission) => permission.celebUserId === chat.recipient!.id),
                    canSend: dmSend.some((permission) => permission.celebUserId === chat.recipient!.id),
                    hasPremium: subscriptionPremiumList.some((subscriptionPremium) => subscriptionPremium.celebUserId === chat.recipient!.id),
                  }
                : chat
          )
          .concat(fakeChats)
          .sort(
            (a, b) =>
              (b.isBroadcast ? 1 : 0) - (a.isBroadcast ? 1 : 0) ||
              (b.hasPremium ? 1 : 0) - (a.hasPremium ? 1 : 0) ||
              (!b.fake ? 1 : 0) - (!a.fake ? 1 : 0) ||
              (b.canEnter ? 1 : 0) - (a.canEnter ? 1 : 0) ||
              (b.lastMessage?.createdAt ?? b.createdAt).getTime() - (a.lastMessage?.createdAt ?? a.createdAt).getTime()
          );
      }),
      shareReplay(1)
    );

    this.chatListSubscription$ = this.chatListSubject.pipe(
      map(
        (chatList) =>
          (chatList ?? [])
            .filter((chat) => chat.isSubscription)
            .sort((a, b) => (b.lastMessage?.createdAt ?? b.createdAt).getTime() - (a.lastMessage?.createdAt ?? a.createdAt).getTime()),
        shareReplay(1)
      )
    );

    this.chatListCelebGroup$ = this.chatListSubject.pipe(
      map(
        (chatList) =>
          (chatList ?? [])
            .filter((chat) => chat.isCelebGroup)
            .sort((a, b) => (b.lastMessage?.createdAt ?? b.createdAt).getTime() - (a.lastMessage?.createdAt ?? a.createdAt).getTime()),
        shareReplay(1)
      )
    );

    this.newMessage$ = this.authService.userId$.pipe(
      switchMap((userId) =>
        userId != null
          ? merge(
              this.socketService.fromEvent('chatMessage'),
              this.socketService.fromEvent('chatMessageSent').pipe(
                filter((messageSent): messageSent is ChatMessageSentSuccess => messageSent.success),
                map((messageSent) => ({
                  ...messageSent.message,
                  sendId: messageSent.sendId,
                }))
              )
            )
          : NEVER
      ),
      share()
    );

    this.messageUpdate$ = this.authService.userId$.pipe(
      switchMap((userId) => (userId != null ? this.socketService.fromEvent('chatMessageUpdate') : NEVER)),
      share()
    );

    this.messageDelete$ = this.authService.userId$.pipe(
      switchMap((userId) => (userId != null ? this.socketService.fromEvent('chatMessageDelete') : NEVER)),
      share()
    );

    this.messageFavoriteUpdate$ = this.authService.userId$.pipe(
      switchMap((userId) => (userId != null ? this.socketService.fromEvent('chatMessageFavoriteUpdate') : NEVER)),
      share()
    );

    this.messageReadUpdate$ = this.authService.userId$.pipe(
      switchMap((userId) => (userId != null ? this.socketService.fromEvent('chatMessageReadUpdate') : NEVER)),
      share()
    );

    this.messageReactionUpdate$ = this.authService.userId$.pipe(
      switchMap((userId) => (userId != null ? this.socketService.fromEvent('chatMessageReactionUpdate') : NEVER)),
      share()
    );
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    completeAll([this.refreshChatListSubject, this.refreshChatListErrorSubject, this.chatUpdateSubject]);
  }

  async init(): Promise<void> {
    await Promise.all([
      this.storage.get(StorageKey.chatList),
      this.storage.get(StorageKey.messageList), // 미구현
    ]).then(([chatList, messageList]) => {
      this.chatListSubject.next(chatList);
    });

    /** 새 메시지 이벤트 (수신, 발신) */
    const newMessage$ = this.authService.userId$.pipe(
      switchMap((userId) =>
        userId != null
          ? merge(
              this.socketService.fromEvent('chatMessage'),
              this.socketService.fromEvent('chatMessageSent').pipe(
                filter((messageSent): messageSent is ChatMessageSentSuccess => messageSent.success),
                map((messageSent) => ({
                  ...messageSent.message,
                  sendId: messageSent.sendId,
                }))
              )
            )
          : NEVER
      ),
      share()
    );

    // init 후 1회 또는 새로고침 요청 시 대화방 목록 불러오기
    this.subscription = this.refreshChatListSubject
      .pipe(startWith(undefined))
      .pipe(
        withLatestFrom(this.authService.userId$),
        switchMap(([, userId]) =>
          userId != null
            ? this.apiService.getChatList({}).pipe(
                map((res) => res.result.chats),
                catchError((err) => {
                  this.refreshChatListErrorSubject.next(err);
                  return EMPTY;
                })
              )
            : of(null)
        )
      )
      .subscribe((chatList) => {
        this.chatListSubject.next(chatList ?? []);
      });

    /** 대화방 업데이트 이벤트 */
    const chatUpdate$ = this.chatUpdateSubject.pipe(map((chat) => ({ chat })));

    // 대화방 생성, 삭제, 업데이트 이벤트 발생 시 대화방 목록 업데이트
    this.subscription = merge(
      this.socketService.fromEvent('chatCreate').pipe(map((ev) => ({ ev, add: true }))),
      this.socketService.fromEvent('chatDelete').pipe(map((ev) => ({ ev, add: false }))),
      chatUpdate$.pipe(map((ev) => ({ ev, add: true })))
    ).subscribe(({ ev, add }) => {
      const newChatList = this.chatListSubject.value.filter((chat) => chat.id !== ev.chat.id);
      if (add) {
        newChatList.push(ev.chat);
      }
      this.chatListSubject.next(newChatList);
    });

    // 대화방 관련 이벤트에서 chatId 추출하여 대화방 가져오는 함수
    const withChat = <T extends { chatId: string }>(ev: T): { ev: T; chat: Chat } | undefined => {
      const chat = this.chatListSubject.value.find((chat2) => chat2.id === ev.chatId);

      return chat != null
        ? {
            ev,
            chat,
          }
        : undefined;
    };

    // 대화방 안 읽은 메시지 수 업데이트 처리
    this.subscription = this.socketService
      .fromEvent('chatUnreadUpdate')
      .pipe(map(withChat), filter(isNotNullish))
      .subscribe(({ ev, chat }) => {
        // 기존 메시지 읽을 때만 서버에서 보내줌
        const newCount = typeof ev.newCount === 'number' ? ev.newCount : (chat.newCount ?? 0) + ev.newCount.$inc;

        this.chatUpdateSubject.next({
          ...chat,
          newCount,
          newFirstId: ev.newFirstId ?? undefined,
          newFirstCreatedAt: ev.newFirstCreatedAt ?? undefined,
        });
      });

    // 대화방 업데이트 이벤트 처리
    this.subscription = this.socketService.fromEvent('chatUpdate').subscribe(({ chat }) => {
      this.chatUpdateSubject.next(chat);
    });

    // 대화방 참가자 추가 이벤트 처리
    this.subscription = this.socketService
      .fromEvent('chatUserAdd')
      .pipe(map(withChat), filter(isNotNullish))
      .subscribe(({ ev, chat }) => {
        for (let i = 0, len = ev.users.length; i < len; i += 1) {
          this.userService.updateUser(ev.users[i]);
        }

        const evUserIds = ev.users.map((user) => user.id);
        const userIds = chat.userIds.concat(evUserIds.filter((userId) => !chat.userIds.includes(userId)));
        this.chatUpdateSubject.next({ ...chat, userIds });
      });

    // 대화방 참가자 제거 이벤트 처리
    this.subscription = this.socketService
      .fromEvent('chatUserRemove')
      .pipe(map(withChat), filter(isNotNullish))
      .subscribe(({ ev, chat }) => {
        for (let i = 0, len = ev.users.length; i < len; i += 1) {
          this.userService.updateUser(ev.users[i]);
        }

        const evUserIds = ev.users.map((user) => user.id);
        const userIds = chat.userIds.filter((userId) => !evUserIds.includes(userId));
        this.chatUpdateSubject.next({ ...chat, userIds });
      });

    // 새 메시지 수신 또는 발신 시 대화방 업데이트
    this.subscription = newMessage$
      .pipe(
        map(withChat),
        filter(isNotNullish),
        withLatestFrom(this.userService.me$),
        map(([{ ev, chat }, userMe]) => (userMe != null ? { ev, chat, userMe } : undefined)),
        filter(isNotNullish)
      )
      .subscribe(({ ev, chat, userMe }) => {
        if (hasAuthor(ev)) {
          this.userService.updateUser(ev.author);
        }

        const newFirst =
          !ev.read && (chat.newFirstId == null || chat.newFirstCreatedAt == null || ev.createdAt < chat.newFirstCreatedAt)
            ? { id: ev.id, createdAt: ev.createdAt }
            : {
                id: chat.newFirstId,
                createdAt: chat.newFirstCreatedAt,
              };

        this.chatUpdateSubject.next({
          ...chat,
          lastMessage: ev,
          newCount: ev.read ? chat.newCount : (chat.newCount ?? 0) + 1,
          newFirstId: newFirst.id,
          newFirstCreatedAt: newFirst.createdAt,
        });
      });

    // 대화방 목록 변경 시 캐시 저장
    this.subscription = this.chatList$.pipe(concatMap((chatList) => this.storage.set(StorageKey.chatList, chatList))).subscribe();

    // 읽음 처리할 메시지 ID 모아서 읽음 처리
    this.subscription = this.readMessageSubject
      .pipe(
        bufferTime(500, null, 100),
        filter((userIds) => userIds.length > 0) // 빈 배열 걸러내기
      )
      .subscribe((messageIds) => {
        this.socketService.emitWaitForAuth('chatMessageRead', {
          chatMessageId: messageIds,
        });
      });
  }

  refreshChatList(): Observable<void> {
    return new Observable<void>((subscriber) => {
      const sub = race(
        this.chatList$.pipe(
          skip(1),
          take(1),
          map(() => undefined)
        ),
        this.refreshChatListErrorSubject.pipe(
          take(1),
          concatMap((err) => throwError(() => err))
        )
      ).subscribe(subscriber);

      this.refreshChatListSubject.next();

      return sub;
    });
  }

  getDirectMessageChat(userId: string): Observable<Chat | null> {
    return this.apiService.getChatDirectMessage({ userId }).pipe(
      map((res) => res.result.chat),
      catchError((err) => {
        console.error(err); // TODO: 오류 처리
        return of(null);
      })
    );
  }

  createChat(userIds: Array<string>): Observable<Chat> {
    return this.apiService.postChatCreate({ userIds }).pipe(map((res) => res.result.chat));
  }

  createDMChat(userId: string): Observable<Chat> {
    return this.apiService.postChatCreate({ userIds: [userId], isDM: true }).pipe(map((res) => res.result.chat));
  }

  leaveChat(chatId: string): Observable<void> {
    return this.apiService.postChatLeave({ chatId }).pipe(map((res) => undefined));
  }

  getMessageHistory(chatId: string, before?: string): Observable<Array<ChatMessage>> {
    return this.apiService.getChatListMessage({ chatId, before }).pipe(map((res) => res.result.chatMessages));
  }

  listMessage(params: GetChatListMessageQueryParams): Observable<GetChatListMessageResult> {
    return this.apiService.getChatListMessage(params).pipe(map((res) => res.result));
  }

  listFavoritedMessage(params: GetChatListFavoritedMessageQueryParams): Observable<GetChatListFavoritedMessageResult> {
    return this.apiService.getChatListFavoritedMessage(params).pipe(map((res) => res.result));
  }

  sendMessage(message: ChatMessageParams): {
    sendId: string;
    observable: Observable<ChatMessage>;
  } {
    const sendId = this.generateSendId();
    this.socketService.emitWaitForAuth('chatMessage', {
      sendId,
      chatMessageParams: message,
    });
    const observable = this.socketService.fromEvent('chatMessageSent').pipe(
      filter((messageResult) => messageResult.sendId === sendId),
      take(1),
      concatMap((messageResult) =>
        messageResult.success
          ? of(messageResult.message)
          : throwError(() => (isCommonErrorJson(messageResult.error) ? new CommonError(messageResult.error) : new Error(messageResult.error.message)))
      ),
      share()
    );
    return {
      sendId,
      observable,
    };
  }

  sendPhoto(
    message: Omit<ChatMessageParams.Photo, 'photoId'>,
    file: File
  ): {
    sendId: string;
    observable: Observable<ChatMessage>;
  } {
    const sendId = this.generateSendId();

    const upload$ = this.apiService.postChatUploadPhoto({ chatId: message.chatId, file }).pipe(share());

    upload$.subscribe((res) => {
      this.socketService.emitWaitForAuth('chatMessage', {
        sendId,
        chatMessageParams: {
          ...message,
          photoId: res.result.file.id,
        },
      });
    });

    const observable = upload$.pipe(
      concatMap(() =>
        this.socketService.fromEvent('chatMessageSent').pipe(
          filter((messageResult) => messageResult.sendId === sendId),
          concatMap((messageResult) =>
            messageResult.success
              ? of(messageResult.message)
              : throwError(() =>
                  isCommonErrorJson(messageResult.error) ? new CommonError(messageResult.error) : new Error(messageResult.error.message)
                )
          )
        )
      )
    );

    return {
      sendId,
      observable,
    };
  }

  sendVideo(
    message: Omit<ChatMessageParams.Video, 'videoId'>,
    file: File
  ): {
    sendId: string;
    observable: Observable<ChatMessage>;
  } {
    const sendId = this.generateSendId();

    const upload$ = this.apiService.postChatUploadVideo({ chatId: message.chatId, file }).pipe(share());

    upload$.subscribe((res) => {
      this.socketService.emitWaitForAuth('chatMessage', {
        sendId,
        chatMessageParams: {
          ...message,
          videoId: res.result.file.id,
        },
      });
    });

    const observable = upload$.pipe(
      concatMap(() =>
        this.socketService.fromEvent('chatMessageSent').pipe(
          filter((messageResult) => messageResult.sendId === sendId),
          concatMap((messageResult) =>
            messageResult.success
              ? of(messageResult.message)
              : throwError(() =>
                  isCommonErrorJson(messageResult.error) ? new CommonError(messageResult.error) : new Error(messageResult.error.message)
                )
          )
        )
      )
    );

    return {
      sendId,
      observable,
    };
  }

  deleteMessage(messageId: string): void {
    this.socketService.emitWaitForAuth('chatMessageDelete', {
      chatMessageId: messageId,
    });
  }

  readMessage(messageId: string | Array<string>): void {
    const messageIds = Array.isArray(messageId) ? messageId : [messageId];
    for (let i = 0, len = messageIds.length; i < len; i += 1) {
      this.readMessageSubject.next(messageIds[i]);
    }
  }

  reportChat(chatId: string, reason: string): Observable<Chat> {
    return this.apiService.postChatReport({ chatId, reason }).pipe(map((res) => res.result.chat));
  }

  toggleDisabled(chatId: string, disabled?: boolean): Observable<Chat> {
    return this.apiService.postChatToggleDisabled({ chatId, disabled }).pipe(map((res) => res.result.chat));
  }

  addReaction(messageId: string, emoji: string): void {
    this.socketService.emitWaitForAuth('chatMessageReactionAdd', {
      chatMessageId: messageId,
      emoji,
    });
  }

  removeReaction(messageId: string, emoji: string): void {
    this.socketService.emitWaitForAuth('chatMessageReactionRemove', {
      chatMessageId: messageId,
      emoji,
    });
  }

  favoriteMessage(messageId: string): Observable<PostChatFavoriteMessageResult> {
    return this.apiService.postChatFavoriteMessage({ chatMessageId: messageId }).pipe(map((res) => res.result));
  }

  unfavoriteMessage(messageId: string): Observable<PostChatUnfavoriteMessageResult> {
    return this.apiService.postChatUnfavoriteMessage({ chatMessageId: messageId }).pipe(map((res) => res.result));
  }

  private generateSendId(): string {
    const now = DateTime.utc();
    const idCounter = (this.idCounter = (this.idCounter % 1000) + 1) + '';
    return now.toFormat('yyyyMMddHHmmssSSS') + idCounter.padStart(3, '0');
  }
}
