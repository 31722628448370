import { defer, interval, merge, Observable, throwError } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, finalize, map, take, takeUntil } from 'rxjs/operators';

interface HasEventTargetAddRemove<T> {
  addEventListener(eventName: string, listener: (ev: T) => void): void;
  removeEventListener(eventName: string, listener: (ev: T) => void): void;
}

// fromEvent 사용 시 창 닫힌 후 removeEventListener 호출 실패로 unsubscribe 오류 발생
function fromEventAlternative<T>(target: HasEventTargetAddRemove<T>, eventName: string): Observable<T> {
  return new Observable<T>((subscriber) => {
    const listener = (ev: T): void => {
      subscriber.next(ev);
    };

    target.addEventListener(eventName, listener);

    return () => {
      target.removeEventListener?.(eventName, listener);
    };
  });
}

export function openWindow<T = unknown>(
  url: string | URL,
  target?: string,
  features?: string,
  closeCheckInterval: number = 1000,
  closeDebounceTime: number = 3000
): Observable<MessageEvent<T>> {
  return defer(() => {
    const win = window.open(url, target, features);

    if (win == null) {
      return throwError(() => new Error('Failed to open a window'));
    }

    const winClose$ = merge(
      fromEventAlternative(win, 'close'),
      interval(closeCheckInterval).pipe(
        map(() => win.closed),
        distinctUntilChanged(),
        debounceTime(closeDebounceTime),
        filter((closed) => closed)
      )
    ).pipe(take(1));

    return fromEventAlternative<MessageEvent<T>>(window, 'message').pipe(
      filter((ev) => ev.source === win),
      takeUntil(winClose$),
      finalize(() => {
        if (!win.closed) {
          win.close();
        }
      })
    );
  });
}
