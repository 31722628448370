// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://app-dev.celebhere.com/api',
  socketOrigin: 'https://app-dev.celebhere.com',
  socketPath: '/socket',
  kakaoAppKey: 'ff965dcd6ea35f548d11b9adf1783909',
  appleOrigin: 'https://app-dev.celebhere.com',
  shareOrigin: 'https://app-dev.celebhere.com',
  androidPackageName: 'com.celebhere.app.dev',
  appStoreId: '6443389251',
  firebaseConfig: {
    apiKey: 'AIzaSyDO6bDoFYBB5Vl6Ao0y33qwN2Z4zPPrqZU',
    authDomain: 'celebhere-dev.firebaseapp.com',
    projectId: 'celebhere-dev',
    storageBucket: 'celebhere-dev.appspot.com',
    messagingSenderId: '392964833313',
    appId: '1:392964833313:web:0a3187d48031690edf6360',
    measurementId: 'G-M1B2ELYZ9X',
  },
  iamport: {
    userCode: 'imp28672065',
    danal: 'danal_tpay.A010012958',
    danalTest: 'danal_tpay.9810030929',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
