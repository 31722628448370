import { Injectable, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { DateWrapped } from '../../../types/date-wrapper';
import { ClientToServerEvents, ServerToClientEvents } from '../../../types/socket-event';
import { AccessTokenService } from '../access-token/access-token.service';
import { BaseService } from '../base.service';
import { SocketStream } from './socket-stream';

// TODO: 타입 정리
type DateUnwrapped<T> = T extends DateWrapped<infer U> ? U : T;
type UnwrapParameters<T extends [...any[]]> = T extends [unknown]
  ? DateUnwrapped<T[0]>
  : { [I in keyof T]: DateUnwrapped<T[I]> } & {
      length: T['length'];
    };

@Injectable({
  providedIn: 'root',
})
export class SocketService extends BaseService implements OnDestroy {
  private readonly appSocketStream = new SocketStream('app');

  constructor(accessTokenService: AccessTokenService) {
    super();

    this.subscription = accessTokenService.accessToken$.subscribe((accessToken) => {
      this.appSocketStream.setAccessToken(accessToken);
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.appSocketStream.destroy();
  }

  fromEvent<Ev extends keyof ServerToClientEvents>(
    eventName: Ev,
    takeUntilDisconnection?: boolean
  ): Observable<UnwrapParameters<Parameters<ServerToClientEvents[Ev]>>> {
    return this.appSocketStream.fromEvent(eventName, takeUntilDisconnection);
  }

  emit<Ev extends keyof ClientToServerEvents>(eventName: Ev, ...args: Parameters<ClientToServerEvents[Ev]>): void {
    this.appSocketStream.emit(eventName, ...args);
  }

  emitWaitForAuth<Ev extends keyof ClientToServerEvents>(eventName: Ev, ...args: Parameters<ClientToServerEvents[Ev]>): void {
    this.appSocketStream.emitWaitForAuth(eventName, ...args);
  }
}
