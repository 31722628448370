import { registerPlugin } from '@capacitor/core';

import type { IamportPlugin } from './definitions';
import { IamportWeb } from './web';

const Iamport = registerPlugin<IamportPlugin>('Iamport', {
  web: () => new IamportWeb(),
});

export * from './definitions';
export { Iamport };
