import { WebPlugin } from '@capacitor/core';
import type { KakaoLoginOAuthToken, KakaoLoginPlugin } from './definitions';

export class KakaoLoginWeb extends WebPlugin implements KakaoLoginPlugin {
  login(): Promise<KakaoLoginOAuthToken> {
    throw new Error('Method not implemented.');
  }

  logout(): Promise<void> {
    throw new Error('Method not implemented.');
  }

  unlink(): Promise<void> {
    throw new Error('Method not implemented.');
  }

  getToken(): Promise<KakaoLoginOAuthToken> {
    throw new Error('Method not implemented.');
  }
}
