import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import {
  GetAuthResetPasswordResult,
  PostAuthResetPasswordResult,
  PostAuthSendEmailCodeParams,
  PostAuthSendEmailCodeResult,
  PostAuthSendPhoneNumberCodeParams,
  PostAuthSendPhoneNumberCodeResult,
  PostAuthSignInAppleParams,
  PostAuthSignInKakaoParams,
  PostAuthSignInParams,
  PostAuthSignUpAppleParams,
  PostAuthSignUpAppleResult,
  PostAuthSignUpKakaoParams,
  PostAuthSignUpKakaoResult,
  PostAuthSignUpParams,
  PostAuthSignUpResult,
  PostAuthVerifyEmailCodeParams,
  PostAuthVerifyEmailCodeResult,
  PostAuthVerifyPhoneNumberCodeParams,
  PostAuthVerifyPhoneNumberCodeResult,
} from '../../../types/api/auth';
import { DeviceInfo } from '../../../types/common';
import { completeAll } from '../../../utils/complete-all';
import { AccessTokenService } from '../access-token/access-token.service';
import { ApiService } from '../api/api.service';
import { BaseService } from '../base.service';
import { CountryService } from '../country/country.service';
import { DeviceInfoService } from '../device-info/device-info.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseService implements OnDestroy {
  get userId(): string | null {
    return this.accessTokenService.userId;
  }
  get authed(): boolean {
    return this.accessTokenService.userId != null;
  }

  readonly userId$: Observable<string | null>;
  readonly authed$: Observable<boolean>;

  private get deviceInfo(): DeviceInfo | undefined {
    return this.deviceInfoService.deviceInfo ?? undefined;
  }

  private readonly userIdSubject = new BehaviorSubject<string | null>(null);

  constructor(
    private accessTokenService: AccessTokenService,
    private apiService: ApiService,
    private countryService: CountryService,
    private deviceInfoService: DeviceInfoService
  ) {
    super();

    this.userId$ = this.accessTokenService.userId$;
    this.authed$ = this.accessTokenService.userId$.pipe(map((userId) => userId != null));
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    completeAll([this.userIdSubject]);
  }

  bootResult(authed: boolean): void {
    if (this.accessTokenService.accessToken && !authed) {
      this.accessTokenService.accessToken = null;
    }
  }

  signIn(params: PostAuthSignInParams): Observable<void> {
    return this.apiService.postAuthSignIn({ deviceInfo: this.deviceInfo, ...params }).pipe(
      tap((res) => (this.accessTokenService.accessToken = res.result.token)),
      map(() => undefined)
    );
  }

  /** @param force `true`일 시 API 호출 즉시 액세스 토큰 값 제거하며 API 호출 실패를 무시 */
  signOut(force: boolean = false): Observable<void> {
    if (force) {
      return new Observable<void>((subscriber) => {
        const sub = this.apiService
          .postAuthSignOut()
          .pipe(
            map(() => undefined),
            catchError(() => of(undefined))
          )
          .subscribe(subscriber);

        this.accessTokenService.accessToken = null;

        return sub;
      });
    }
    return this.apiService.postAuthSignOut().pipe(
      tap(() => (this.accessTokenService.accessToken = null)),
      map(() => undefined)
    );
  }

  signUp(params: Omit<PostAuthSignUpParams, 'deviceInfo'>): Observable<PostAuthSignUpResult> {
    return this.apiService.postAuthSignUp({ deviceInfo: this.deviceInfo, ...params }).pipe(
      tap((res) => (this.accessTokenService.accessToken = res.result.token)),
      map((res) => res.result)
    );
  }

  sendEmailCode(params: PostAuthSendEmailCodeParams): Observable<PostAuthSendEmailCodeResult> {
    return this.apiService.postAuthSendEmailCode(params).pipe(map((res) => res.result));
  }

  verifyEmailCode(params: PostAuthVerifyEmailCodeParams): Observable<PostAuthVerifyEmailCodeResult> {
    return this.apiService.postAuthVerifyEmailCode(params).pipe(map((res) => res.result));
  }

  signInApple(params: PostAuthSignInAppleParams): Observable<void> {
    return this.apiService.postAuthSignInApple({ deviceInfo: this.deviceInfo, ...params }).pipe(
      tap((res) => (this.accessTokenService.accessToken = res.result.token)),
      map((res) => undefined)
    );
  }

  signInKakao(params: PostAuthSignInKakaoParams): Observable<void> {
    return this.apiService.postAuthSignInKakao({ deviceInfo: this.deviceInfo, ...params }).pipe(
      tap((res) => (this.accessTokenService.accessToken = res.result.token)),
      map((res) => undefined)
    );
  }

  signUpApple(params: Omit<PostAuthSignUpAppleParams, 'deviceInfo'>): Observable<PostAuthSignUpAppleResult> {
    return this.apiService.postAuthSignUpApple({ deviceInfo: this.deviceInfo, ...params }).pipe(
      tap((res) => (this.accessTokenService.accessToken = res.result.token)),
      map((res) => res.result)
    );
  }

  signUpKakao(params: Omit<PostAuthSignUpKakaoParams, 'deviceInfo'>): Observable<PostAuthSignUpKakaoResult> {
    return this.apiService.postAuthSignUpKakao({ deviceInfo: this.deviceInfo, ...params }).pipe(
      tap((res) => (this.accessTokenService.accessToken = res.result.token)),
      map((res) => res.result)
    );
  }

  deleteAccount(): Observable<void> {
    return this.apiService.postAuthDeleteAccount({}).pipe(
      tap(() => (this.accessTokenService.accessToken = null)),
      map((res) => undefined)
    );
  }

  changePassword(currentPassword: string, newPassword: string): Observable<void> {
    return this.apiService.postAuthChangePassword({ currentPassword, newPassword }).pipe(map(() => undefined));
  }

  sendResetPasswordLink(email: string): Observable<void> {
    return this.apiService.postAuthSendResetPasswordLink({ email }).pipe(map(() => undefined));
  }

  resetPasswordCheck(code: string): Observable<GetAuthResetPasswordResult> {
    return this.apiService.getAuthResetPassword({ code }).pipe(map((res) => res.result));
  }

  resetPassword(code: string, newPassword: string): Observable<PostAuthResetPasswordResult> {
    return this.apiService.postAuthResetPassword({ code, newPassword }).pipe(map((res) => res.result));
  }

  sendPhoneNumberCode(params: PostAuthSendPhoneNumberCodeParams): Observable<PostAuthSendPhoneNumberCodeResult> {
    return this.apiService.postAuthSendPhoneNumberCode(params).pipe(map((res) => res.result));
  }

  verifyPhoneNumberCode(params: PostAuthVerifyPhoneNumberCodeParams): Observable<PostAuthVerifyPhoneNumberCodeResult> {
    return this.apiService.postAuthVerifyPhoneNumberCode(params).pipe(map((res) => res.result));
  }
}
