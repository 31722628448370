import { Injectable } from '@angular/core';
import { fromEvent, merge, Observable } from 'rxjs';
import { shareReplay, take, tap } from 'rxjs/operators';
import { Iamport } from '../../../types/iamport';

@Injectable({
  providedIn: 'root',
})
export class ScriptService {
  readonly iamport$ = this.load$('https://cdn.iamport.kr/v1/iamport.js', () => (window as any).IMP as Iamport);
  // TODO: 카카오 로그인 업그레이드
  // readonly kakao$ = this.load$('https://developers.kakao.com/sdk/js/kakao.js', () => window.Kakao);
  readonly kakao$ = this.load$('https://t1.kakaocdn.net/kakao_js_sdk/v1/kakao.js', () => window.Kakao);

  private load$<T>(src: string, callback: () => T): Observable<T> {
    return new Observable<T>((subscriber) => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.classList.add('script-service');
      script.crossOrigin = 'anonymous';
      document.head.appendChild(script);

      const sub = merge(
        fromEvent(script, 'load').pipe(
          take(1),
          tap(() => {
            subscriber.next(callback());
            subscriber.complete();
          })
        ),
        fromEvent(script, 'error').pipe(
          take(1),
          tap((err) => {
            subscriber.error(err);
          })
        )
      ).subscribe();

      script.src = src;

      return sub;
    }).pipe(shareReplay(1));
  }
}
