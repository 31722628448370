import { Injectable, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import {
  Observable,
  Subject,
  bufferTime,
  catchError,
  concatMap,
  defer,
  filter,
  map,
  merge,
  mergeMap,
  of,
  race,
  share,
  take,
  tap,
  throwError,
} from 'rxjs';
import { CommonError } from '../../../../common-error';
import { APIErrorCode } from '../../../constants/api-error-code';
import { User } from '../../../types/common';
import { completeAll } from '../../../utils/complete-all';
import { isAPIErrorWithCode } from '../../../utils/is-api-error-with-code';
import { ApiService } from '../api/api.service';
import { BaseService } from '../base.service';
import { UserCacheService } from '../user-cache/user-cache.service';

/**
 * 사용자 정보 서버 요청 도와주는 서비스
 */
@Injectable({
  providedIn: 'root',
})
export class UserLoaderService extends BaseService implements OnDestroy {
  /** 로딩 중인 사용자 ID 및 사용자명 목록 */
  readonly serverLoadingUserIds: Array<string> = [];

  private readonly serverLoadSubject = new Subject<string>();
  private readonly serverLoadedSubject = new Subject<User>();
  private readonly serverLoadErrorSubject = new Subject<[string, any]>();

  constructor(private translateService: TranslateService, private apiService: ApiService, private userCacheService: UserCacheService) {
    super();

    // 서버 로드 요청 처리
    this.subscription = defer(() => {
      const serverLoad$ = this.serverLoadSubject.pipe(
        filter((userId) => {
          if (this.serverLoadingUserIds.includes(userId)) {
            return false;
          } else {
            this.serverLoadingUserIds.push(userId);
            return true;
          }
        }),
        share()
      );
      return merge(
        // 사용자 ID로 로드하는 부분
        serverLoad$.pipe(
          filter((v) => !v.startsWith('@')),
          bufferTime(500, null, 100), // 500ms 지나거나 100개 차면 로딩할 사용자 ID 배열로 묶기
          filter((userIds) => userIds.length > 0), // 빈 배열 걸러내기
          mergeMap((userIds) =>
            this.apiService.getUserProfile({ userId: userIds }).pipe(
              map((res) => [userIds, res.result.users, undefined] as const),
              catchError((err) => of([userIds, null, err] as const))
            )
          ),
          tap(([userIds, users, err]) => {
            // 로딩 중인 사용자 ID 목록에서 제외
            for (const userId of userIds) {
              const index = this.serverLoadingUserIds.indexOf(userId);
              if (index > -1) {
                this.serverLoadingUserIds.splice(index, 1);
              }
            }

            if (err == null && users != null) {
              const resultUserIds = users.map((user) => user.id);
              const notFoundUserIds = userIds.filter((userId) => !resultUserIds.includes(userId));
              // 오류 없이 조회된 사용자 성공 이벤트 방출
              for (let i = 0, len = users.length; i < len; i += 1) {
                this.serverLoadedSubject.next(users[i]);
              }
              // 오류는 없었으나 조회가 안 된 사용자는 실패 이벤트 방출
              for (let i = 0, len = notFoundUserIds.length; i < len; i += 1) {
                this.serverLoadErrorSubject.next([
                  notFoundUserIds[i],
                  new CommonError(this.translateService.instant('ERROR.USER_NOT_FOUND'), APIErrorCode.USER_NOT_FOUND),
                ]);
              }
            } else {
              // 오류 있으면 실패 이벤트 방출
              for (let i = 0, len = userIds.length; i < len; i += 1) {
                this.serverLoadErrorSubject.next([userIds[i], err]);
              }
            }
          })
        ),
        // 사용자명으로 로드하는 부분
        serverLoad$.pipe(
          filter((v) => v.startsWith('@')),
          bufferTime(500, null, 100), // 500ms 지나거나 100개 차면 로딩할 사용자명 배열로 묶기
          filter((usernames) => usernames.length > 0), // 빈 배열 걸러내기
          mergeMap((usernames) =>
            this.apiService
              .getUserProfile({
                username: usernames.map((username) => username.slice(1)),
              })
              .pipe(
                map((res) => [usernames, res.result.users, undefined] as const),
                catchError((err) => of([usernames, null, err] as const))
              )
          ),
          tap(([usernames, users, err]) => {
            // 로딩 중인 사용자명 목록에서 제외
            for (const username of usernames) {
              const index = this.serverLoadingUserIds.indexOf(username);
              if (index > -1) {
                this.serverLoadingUserIds.splice(index, 1);
              }
            }

            if (err == null && users != null) {
              const resultUsernames = users.map((user) => user.celebUsername);
              const notFoundUsernames = usernames.map((username) => username.slice(1)).filter((username) => !resultUsernames.includes(username));
              // 오류 없이 조회된 사용자 성공 이벤트 방출
              for (let i = 0, len = users.length; i < len; i += 1) {
                this.serverLoadedSubject.next(users[i]);
              }
              // 오류는 없었으나 조회가 안 된 사용자는 실패 이벤트 방출
              for (let i = 0, len = notFoundUsernames.length; i < len; i += 1) {
                this.serverLoadErrorSubject.next([
                  notFoundUsernames[i],
                  new CommonError(this.translateService.instant('ERROR.USER_NOT_FOUND'), APIErrorCode.USER_NOT_FOUND),
                ]);
              }
            } else {
              // 오류 있으면 실패 이벤트 방출
              for (let i = 0, len = usernames.length; i < len; i += 1) {
                this.serverLoadErrorSubject.next([usernames[i], err]);
              }
            }
          })
        )
      );
    }).subscribe();

    // 서버 요청 성공 시 캐시 추가
    this.subscription = this.serverLoadedSubject
      .pipe(
        tap((user) => {
          this.userCacheService.setUser(user, DateTime.local().plus({ minute: 10 }));
        })
      )
      .subscribe();

    // 서버 요청 실패 시 캐시 삭제
    this.subscription = this.serverLoadErrorSubject
      .pipe(
        tap(([userIdOrUsername, err]) => {
          if (isAPIErrorWithCode(err, APIErrorCode.USER_NOT_FOUND)) {
            const username = userIdOrUsername.startsWith('@') ? userIdOrUsername.slice(1) : undefined;
            const userId = username != null ? this.userCacheService.getUsername(username) : userIdOrUsername;

            if (username) {
              this.userCacheService.deleteUsername(username);
            }
            if (userId) {
              this.userCacheService.deleteUser(userId);
            }
          }
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    completeAll([this.serverLoadSubject, this.serverLoadedSubject, this.serverLoadErrorSubject]);
    this.serverLoadingUserIds.length = 0;
  }

  /**
   * 사용자 정보 서버에 요청하여 가져오기
   *
   * 요청 결과에 따라 자동으로 캐시 처리됨
   */
  fetchUser(userId: string): Observable<User> {
    return new Observable<User>((subscriber) => {
      // 성공과 실패 중 더 빠른 값 방출
      const sub = race(
        // ID 일치하는 로드 성공 이벤트 발생 시 User
        this.serverLoadedSubject.pipe(
          filter((user) => user.id === userId),
          take(1)
        ),
        // ID 일치하는 로드 실패 이벤트 발생 시 오류 내보내기
        this.serverLoadErrorSubject.pipe(
          filter(([userId2]) => userId2 === userId),
          take(1),
          concatMap(([, err]) => throwError(() => err))
        )
      ).subscribe(subscriber);

      // 로컬 저장소 로드 요청
      this.serverLoadSubject.next(userId);

      return sub;
    });
  }

  /**
   * 사용자 ID 또는 사용자명으로 사용자 정보를 지속적으로 가져옴
   *
   * 서버에 요청을 보내지는 않으며 요청을 따로 보내려면 {@link requestUser} 호출
   */
  getUser(userIdOrUsername: string): Observable<User | null> {
    const username = userIdOrUsername.startsWith('@') ? userIdOrUsername.slice(1) : undefined;
    const userId = userIdOrUsername.startsWith('@') ? undefined : userIdOrUsername;

    return merge(
      this.serverLoadedSubject.pipe(
        filter((user) => {
          if (username != null && user.celebUsername === username) {
            return true;
          }
          if (userId != null && user.id === userId) {
            return true;
          }
          return false;
        })
      ),
      this.serverLoadErrorSubject.pipe(
        filter(([userIdOrUsername2, err]) => {
          if (isAPIErrorWithCode(err, APIErrorCode.USER_NOT_FOUND)) {
            if (username != null && userIdOrUsername2 === '@' + username) {
              return true;
            }
            if (userId != null && userIdOrUsername2 === userId) {
              return true;
            }
          }
          return false;
        }),
        map(() => null)
      )
    );
  }

  /**
   * 사용자 정보 요청을 서버로 보냄
   *
   * 요청 결과에 따라 자동으로 캐시 처리됨
   *
   * 결과값을 받으려면 {@link getUser} 호출
   */
  requestUser(userIdOrUsername: string): void {
    this.serverLoadSubject.next(userIdOrUsername);
  }
}
