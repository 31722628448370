import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { concatMap, map, of } from 'rxjs';
import { OpenInAppService } from '../../services/open-in-app/open-in-app.service';
import { UserService } from '../../services/user/user.service';

marker('STORE.CANNOT_BUY_TP_ON_APP');
marker('STORE.READ_FAQ');

/** 웹에서 결제 시도 시 앱 실행 시도 */
export const pointBuyGuard: CanActivateFn = (route, state) => {
  const openInAppService = inject(OpenInAppService);
  const userService = inject(UserService);

  return userService.me$.pipe(
    concatMap((user) => {
      if (Capacitor.isNativePlatform()) {
        // IAP 강제 시 주석 해제
        // if (Capacitor.getPlatform() === 'android') {
        //   const extras = router.getCurrentNavigation()?.extras;
        //   if (!extras?.state?.fromDeepLink) {
        //     alertService
        //       .confirm(
        //         translateService.instant('COMMON.ALERT'),
        //         translateService.instant('STORE.CANNOT_BUY_TP_ON_APP'),
        //         translateService.instant('STORE.READ_FAQ'),
        //         translateService.instant('COMMON.CLOSE')
        //       )
        //       .then((yes) => {
        //         if (yes) {
        //           window.open(
        //             user?.isCeleb ? FAQ_LINK_CELEB : FAQ_LINK,
        //             '_blank'
        //           );
        //         }
        //       });
        //     return of(false);
        //   }
        // }
      } else {
        return openInAppService.openInApp(state.url, 1000).pipe(map(() => true));
      }
      return of(true);
    })
  );
};
